import React, {useEffect} from 'react'
import {
  FlatList as RNFlatList,
  Pressable,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'

import {NavigationProp} from '#/lib/routes/types'
import {isMobile} from '#/platform/detection'
import * as persisted from '#/state/persisted'
import {useSession} from '#/state/session'
import {usePostPreState} from '#/state/shell/post-pre-data'
import {MaskEmptyIcon} from '#/view/icons/EmptyIcons'
import {formatHandler} from '#/screens/Onboarding/util'
import {atoms as a, useTheme} from '#/alf'
import * as Dialog from '#/components/Dialog'
import {
  AnonModeIcon,
  AnonModePersonIcon,
  CheckedIcon,
} from '#/components/icons/StakeIcons'
import {Button} from '../forms/Button'
import {ToggleButton} from '../forms/ToggleButton'
import {UserAvatar} from '../UserAvatar'

export type PostAccountType = {
  name: string
  did: string
  handle: string
  avatar?: string
  parentDid?: string
}

type Props = {
  sltAccount: PostAccountType
  accounts: PostAccountType[]
  visibleType: string
  control: Dialog.DialogControlProps
  isAnonymous?: boolean
  setIsAnonymous?: (value: boolean) => void
  onDone: (value: PostAccountType, value2?: boolean) => void
}
export function PostMakeAs(props: Props) {
  const navigation = useNavigation<NavigationProp>()
  const {control, accounts, visibleType} = props
  const t = useTheme()
  const {_} = useLingui()
  const {currentAccount} = useSession()
  const isToCircle = visibleType !== 'Public'

  const [sltAccount, setSltAccount] = React.useState<PostAccountType>(
    props?.sltAccount,
  )
  const [isAnonymous, setIsAnonymous] = React.useState<boolean>(
    props?.isAnonymous ?? false,
  )
  const isCurrentMain = !accounts?.find(o => o?.did === currentAccount?.did)
    ?.parentDid

  const renderItemInner = React.useCallback(
    ({item}: {item: PostAccountType}) => {
      const isSelected = sltAccount?.did === item?.did
      const isMain = !item?.parentDid || item?.parentDid === ''
      return (
        <Pressable
          accessibilityRole="button"
          key={item?.did}
          onPress={() => {
            setSltAccount(item)
          }}
          style={[
            a.p_md,
            a.border,
            a.rounded_lg,
            a.flex_row,
            a.justify_between,
            a.align_center,
            a.mb_md,
            {
              borderWidth: isSelected ? 2 : 1,
              borderColor: isSelected ? t.palette.green_11 : '#97979733',
            },
          ]}>
          <View
            style={[
              a.flex_1,
              a.relative,
              a.flex_row,
              a.align_center,
              a.overflow_hidden,
            ]}>
            <View
              style={[
                a.align_center,
                a.justify_center,
                {
                  borderWidth: 2,
                  borderRadius: 24,
                  borderColor: isSelected ? t.palette.green_11 : 'transparent',
                },
              ]}>
              <UserAvatar
                avatar={item?.avatar}
                size={40}
                style={[
                  {margin: 2, alignItems: 'center', justifyContent: 'center'},
                ]}
              />
              {isSelected && isAnonymous && isToCircle && (
                <View
                  style={[
                    a.absolute,
                    {
                      backgroundColor: 'rgba(0,0,0,0.5))',
                      width: 40,
                      height: 40,
                      borderRadius: 20,
                    },
                  ]}
                />
              )}
              {isSelected && (
                <View style={[a.absolute, {bottom: 0, right: -5}]}>
                  <CheckedIcon size={16} />
                </View>
              )}
              {isSelected && isAnonymous && isToCircle && (
                <View style={[a.absolute, {top: 15, left: 4}]}>
                  <AnonModePersonIcon />
                </View>
              )}
            </View>
            <View style={[a.flex_1, a.flex_col, a.ml_sm]}>
              <View style={[a.flex_1, a.flex_row, a.align_center, a.gap_sm]}>
                <Text
                  style={[a.text_lg, a.font_bold, t.atoms.text]}
                  numberOfLines={1}
                  ellipsizeMode="tail">
                  {item?.name && item?.name !== ''
                    ? item?.name
                    : formatHandler(item?.handle)}
                </Text>
                {isMain && (
                  <View
                    style={[
                      t.atoms.bg_primary,
                      a.rounded_xs,
                      {paddingHorizontal: 4, paddingVertical: 2},
                    ]}>
                    <Text style={[a.text_xs, a.font_semibold]}>
                      Main account
                    </Text>
                  </View>
                )}
              </View>
              <Text style={[a.text_sm, {color: t.palette.gray_10}]}>
                @{formatHandler(item?.handle)}
              </Text>
            </View>
          </View>
          <View style={[a.px_lg]}>
            {isToCircle && isSelected && (
              <ToggleButton
                type="primary"
                label=""
                isSelected={isAnonymous ?? false}
                style={[
                  a.p_0,
                  a.justify_between,
                  a.align_center,
                  {
                    backgroundColor: isAnonymous
                      ? t.palette.primary
                      : t.palette.gray_10,
                  },
                ]}
                removeOuterStyle={true}
                onPress={() => {
                  setIsAnonymous?.(!isAnonymous)
                }}
              />
            )}
          </View>
        </Pressable>
      )
    },
    [
      t.palette.gray_10,
      sltAccount?.did,
      isAnonymous,
      setIsAnonymous,
      t.palette.green_11,
      t.atoms.bg_primary,
      t.palette.primary,
      isToCircle,
      t.atoms.text,
    ],
  )

  return (
    <View style={[a.flex_1, {marginHorizontal: -8, marginTop: -8}]}>
      <View style={[a.flex_row, a.justify_center, a.align_center]}>
        <Text
          style={[
            a.text_lg,
            a.font_bold,
            a.text_center,
            a.mb_sm,
            t.atoms.text,
          ]}>
          <Trans>Spill as</Trans>
        </Text>
      </View>
      {isToCircle && (
        <View style={[a.flex_row, a.align_center, a.justify_between]}>
          <View style={[a.flex_row, a.align_center]}>
            <View style={[a.ml_sm]}>
              <View style={[a.flex_row, a.align_center, a.gap_sm]}>
                <Text style={[a.text_sm, a.font_bold, t.atoms.text]}>
                  <Trans>Annoymmous mode</Trans>
                </Text>
                <AnonModeIcon style={[t.atoms.text]} />
              </View>
              <Text style={{color: t.palette.gray_10}}>
                Speak freely, with no personal information shown.
              </Text>
            </View>
          </View>
        </View>
      )}
      <View style={[a.pt_md]}>
        <RNFlatList
          testID={'transassetsflatlist'}
          data={accounts}
          renderItem={renderItemInner}
          removeClippedSubviews={true}
          // @ts-ignore our .web version only -prf
          desktopFixedHeight
        />
        {isCurrentMain && (
          <>
            {accounts?.length <= 1 ? (
              <View
                style={[
                  a.flex_row,
                  a.gap_md,
                  a.border,
                  a.rounded_md,
                  a.p_lg,
                  isMobile ? a.pb_md : a.pb_0,
                  {borderColor: '#97979733'},
                ]}>
                <View style={[a.flex_1]}>
                  <Text style={[a.text_lg, a.font_bold, a.pb_xs, t.atoms.text]}>
                    Create a mask?
                  </Text>
                  <Text style={[a.text_sm, {color: t.palette.gray_10}]}>
                    You can choose your own identity when expressing your
                    opinions.
                  </Text>
                  <Button
                    testID="goCreateMaskBtn"
                    type="default"
                    onPress={() => {
                      control.close()
                      navigation.navigate('SavedMask')
                    }}
                    accessibilityLabel={_(msg`Create`)}
                    accessibilityHint=""
                    label={_(msg`Create`)}
                    labelContainerStyle={{justifyContent: 'center', padding: 4}}
                    labelStyle={[a.text_md]}
                    style={[
                      a.mt_lg,
                      {backgroundColor: t.palette.primary, width: 95},
                    ]}
                  />
                </View>
                <View>
                  <MaskEmptyIcon size={136} />
                </View>
              </View>
            ) : (
              <TouchableOpacity
                accessibilityRole="button"
                onPress={() => {
                  control.close()
                  navigation.navigate('SavedMask')
                }}
                style={[
                  a.p_md,
                  a.border,
                  a.rounded_lg,
                  a.flex_row,
                  a.align_center,
                  a.mb_md,
                  a.gap_md,
                  {
                    borderWidth: 1,
                    borderColor: '#97979733',
                  },
                ]}>
                <View
                  style={{
                    marginLeft: 3,
                    borderWidth: 1,
                    borderStyle: 'dashed',
                    borderColor: t.palette.gray_10,
                    width: 40,
                    height: 40,
                    borderRadius: 40,
                    justifyContent: 'center',
                    alignItems: 'center',
                    backgroundColor: '#E8E8E8',
                  }}>
                  <FontAwesomeIcon
                    icon={'plus'}
                    size={20}
                    color={t.palette.gray_10}
                  />
                </View>
                <Text
                  style={[
                    a.text_lg,
                    a.font_semibold,
                    {color: t.palette.black_25},
                  ]}>
                  Create mask
                </Text>
              </TouchableOpacity>
            )}
          </>
        )}
      </View>
      <Button
        testID="cancelBtn"
        type="default"
        onPress={() => {
          props?.onDone(sltAccount, isAnonymous)
          control.close()
        }}
        accessibilityLabel={_(msg`Done`)}
        accessibilityHint=""
        label={_(msg`Done`)}
        labelContainerStyle={{justifyContent: 'center', padding: 4}}
        labelStyle={[a.text_md, {color: t.palette.black}]}
        style={[a.mt_lg, {backgroundColor: t.palette.primary}]}
      />
    </View>
  )
}

export const usePostMask = ({visible}: {visible: string}) => {
  const markAsControl = Dialog.useDialogControl()
  const {accounts} = usePostPreState()
  const {currentAccount} = useSession()
  const postMakeDid = persisted.get('postMakeDid')
  const postIsAnonymous = persisted.get('postIsAnonymous')
  const isToCircle = visible !== 'Public'

  const [sltAccount, setSltAccount] = React.useState<PostAccountType>()
  const [isAnonymous, setIsAnonymous] = React.useState<boolean>(
    !isToCircle ? false : postIsAnonymous,
  )

  useEffect(() => {
    if (postMakeDid && accounts?.find(o => postMakeDid === o?.did)) {
      setSltAccount(accounts?.find(o => postMakeDid === o?.did))
    } else {
      setSltAccount(accounts?.find(o => currentAccount?.did === o?.did))
    }
  }, [accounts, postMakeDid, currentAccount])

  const makeAsDialog = (
    <Dialog.Outer
      control={markAsControl}
      nativeOptions={{sheet: {snapPoints: ['50%']}}}>
      <Dialog.Handle />

      <Dialog.ScrollableInner
        accessibilityDescribedBy="dialog-description"
        accessibilityLabelledBy="dialog-title">
        <View style={[a.relative, a.gap_md, a.w_full, a.flex_1]}>
          {sltAccount && (
            <PostMakeAs
              control={markAsControl}
              accounts={accounts}
              visibleType={visible}
              sltAccount={sltAccount}
              isAnonymous={isAnonymous}
              setIsAnonymous={setIsAnonymous}
              onDone={(value: PostAccountType, value2?: boolean) => {
                persisted.write('postMakeDid', value.did)
                setSltAccount(value)
                if (isToCircle) {
                  persisted.write('postIsAnonymous', value2 ?? false)
                  setIsAnonymous(value2 ?? false)
                }
              }}
            />
          )}
        </View>
      </Dialog.ScrollableInner>
    </Dialog.Outer>
  )

  return {
    markAsControl,
    makeAsDialog,
    sltAccount,
    isAnonymous,
  }
}
