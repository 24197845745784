import React from 'react'

import {Tag} from '#/view/com/tags/TagItem'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {List} from '#/view/com/util/List'
import {ScrollView} from '#/view/com/util/Views'
import {ContactItemProps} from '../Circle'
import ContactItem from './ContactItem'

type Props = {
  users?: ContactItemProps[]
  selected?: ContactItemProps[]
  historyMembers?: ContactItemProps[]
  showCheckbox?: boolean
  itemType?: 'menu' | 'normal'
  showTag?: boolean
  filterTags?: Tag[]
  showDegree?: boolean
  showInvite?: boolean
  onSelected?: (selected: boolean, item: ContactItemProps) => void
  onClickItem?: (item: ContactItemProps) => void
}
export default function ContactList({
  users,
  selected,
  historyMembers,
  showCheckbox = true,
  itemType,
  filterTags,
  showTag,
  showDegree,
  showInvite,
  onSelected,
  onClickItem,
}: Props) {
  if (!users?.length) {
    return (
      <EmptyBox
        icon="tea"
        message={'there is no contacts'}
        viewStyles={{paddingTop: 20, paddingBottom: 20}}
      />
    )
  }

  const renderItem = ({item}: {item: ContactItemProps; index: number}) => {
    const isSelected = !!selected?.find(_item => _item.id === item.id)
    const disabled = !!historyMembers?.find(_item => _item.id === item.id)
    return (
      <ContactItem
        showCheckbox={showCheckbox}
        key={`${item.id}`}
        selected={isSelected}
        disabled={disabled}
        itemType={itemType}
        showTag={showTag}
        showDegree={showDegree}
        showInvite={showInvite}
        filterTags={filterTags}
        user={item}
        onSelected={hasSelected => onSelected?.(hasSelected, item)}
        onPress={onClickItem}
      />
    )
  }

  const keyExtractor = (item: ContactItemProps) => {
    return `${item.id}}`
  }

  return (
    <ScrollView contentContainerStyle={{borderWidth: 0, minHeight: '100%'}}>
      <List
        sideBorders={false}
        data={users}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
      />
    </ScrollView>
  )
}
