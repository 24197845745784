import React, {useState} from 'react'
import {SafeAreaView, Text, View} from 'react-native'
import {useNavigation} from '@react-navigation/native'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {useModalControls} from '#/state/modals'
import * as persisted from '#/state/persisted'
import {NavigationProp} from 'lib/routes/types'
import {atoms as a, useTheme} from '#/alf'
import * as Toggle from '#/components/forms/Toggle'
import {EmptyWalletIcon} from '#/components/icons/StakeIcons'
import {Button} from '../util/forms/Button'

export const snapPoints = ['50%']
export const Component = function DetectLinkedWalletModalComponent() {
  const t = useTheme()
  const navigation = useNavigation<NavigationProp>()
  const {closeModal} = useModalControls()
  const {isMobile, isDesktop} = useWebMediaQueries()

  const [showAgain, setShowAgain] = useState<boolean>(false)

  return (
    <SafeAreaView testID="detectLinkedWalletModal">
      <View style={[a.mt_xl, isMobile && {paddingHorizontal: 18}]}>
        <View style={[a.align_center]}>
          <EmptyWalletIcon style={{width: 151, height: 151}} />
          <Text
            style={[
              a.text_lg,
              a.font_semibold,
              a.text_center,
              a.pt_md,
              t.atoms.text,
            ]}>
            Enable tipping by linking your wallet account
          </Text>
        </View>
        <View
          style={[
            a.flex_row,
            a.align_center,
            a.justify_center,
            a.gap_sm,
            a.my_xl,
          ]}>
          <Toggle.Group
            type="checkbox"
            label="Don't show again"
            values={showAgain ? ['hideRemind'] : []}
            onChange={value => {
              setShowAgain(value?.[0] === 'hideRemind' ? true : false)
            }}>
            <Toggle.Item name="hideRemind" label="Don't show again">
              <Toggle.Checkbox
                style={[a.rounded_xs, {width: 18, height: 18}]}
              />
            </Toggle.Item>
          </Toggle.Group>
          <Text style={[a.text_sm, {color: t.palette.black_25}]}>
            Don't show again
          </Text>
        </View>
        <View style={[a.mt_md, a.gap_md, isDesktop ? a.flex_row_reverse : {}]}>
          <Button
            testID="confirmBtn"
            type="primary"
            accessibilityLabel={'Link Wallet'}
            accessibilityHint=""
            label={'Go'}
            onPress={() => {
              navigation.navigate('LinkedWallet')
              closeModal()
            }}
            labelContainerStyle={{justifyContent: 'center', padding: 4}}
            labelStyle={[a.text_md, {color: t.palette.black}]}
            style={[
              isDesktop ? a.flex_1 : {},
              {backgroundColor: t.palette.primary},
            ]}
          />
          <Button
            testID="cancelBtn"
            type="default"
            onPress={() => {
              persisted.write('hideLinkWalletRemind', showAgain)
              closeModal()
            }}
            accessibilityLabel={'Close'}
            accessibilityHint=""
            label={'Close'}
            labelContainerStyle={{justifyContent: 'center', padding: 4}}
            labelStyle={[a.text_md]}
            style={[isDesktop ? a.flex_1 : {}]}
          />
        </View>
      </View>
    </SafeAreaView>
  )
}
