import React, {useMemo} from 'react'
import {StyleSheet, Text} from 'react-native'
import {LinearGradient} from 'expo-linear-gradient'

const colorsArray = [
  ['#FF9C65', '#FC774B'],
  ['#B994FF', '#9C6AFF'],
  ['#889DFF', '#597CFF'],
  ['#FF8177', '#FF4B41'],
  ['#44CDFF', '#10A4FF'],
  ['#00E285', '#00B94C'],
  ['#FFCC69', '#FFA95B'],
  ['#EF8CFF', '#DA47F1'],
  ['#5F82FF', '#47FBC5'],
  ['#C059FF', '#FF7D54'],
  ['#5B6CFF', '#D767FF'],
  ['#FF9446', '#5873FF'],
  ['#68C6FB', '#FF9738'],
]

export function UserTextAvatar({name}: {name: string}) {
  const shotName = useMemo(() => {
    const strArray = name.toUpperCase().split(' ')
    const first = strArray[0]?.[0] ? strArray[0]?.[0] : ''
    const second = strArray[1]?.[0] ? `${strArray[1]?.[0]}` : ''
    return first + second
  }, [name])

  const background = useMemo(() => {
    const index = shotName.charCodeAt(0)
    return colorsArray[Number(index) % colorsArray.length]
  }, [shotName])

  return (
    <LinearGradient
      colors={background}
      style={styles.page}
      start={{x: 0.5, y: 0}}
      end={{x: 0.5, y: 1}}>
      <Text style={styles.text}>{shotName}</Text>
    </LinearGradient>
  )
}

export function getAvatartConfig(value: string) {
  const hashCode = Array.from(value).reduce((hash, char) => {
    return hash + char.charCodeAt(0)
  }, 0)

  const index = hashCode % colorsArray.length
  return colorsArray[Number(index) % colorsArray.length]
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: '100%',
    borderRadius: 100,
    paddingVertical: 10,
    alignItems: 'center',
    justifyContent: 'center',
  },
  text: {
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold',
  },
})
