import React from 'react'
import {Image as RNImage} from 'react-native-image-crop-picker'
import {AppBskyActorDefs, AppBskyGraphDefs} from '@atproto/api'

import {useNonReactiveCallback} from '#/lib/hooks/useNonReactiveCallback'
import {GalleryModel} from '#/state/models/media/gallery'
import {ImageModel} from '#/state/models/media/image'
import {BetToEarnModalProps} from '#/view/com/modals/BetToEarn'
import {
  PostSelectCircleModalProps,
  VisibleRangeType,
} from '#/view/com/modals/PostModal/PostSelectCircle'
import {SelectUnvisibleModalProps} from '#/view/com/modals/PostModal/SelectUnvisible'
import {StakeDonateModalProps} from '#/view/com/modals/StakeDonate'
import {TelegramBindStep} from '#/view/com/modals/TelegramModal/config'
import {TransResultModalProps} from '#/view/com/modals/TranstionResult'
import {WithdrawModalType} from '#/view/com/modals/Withdraw'
import {WithdrawPreviewModalProps} from '#/view/com/modals/WithdrawPreview'

export interface EditProfileModal {
  name: 'edit-profile'
  profile: AppBskyActorDefs.ProfileViewDetailed
  onUpdate?: () => void
}

export interface ILinkSocialModal {
  name: 'link-social'
  onCallBack?: (telegram: string) => void
  hideTelegramBt?: boolean
}
export interface ITelegramModal {
  name: 'telegram'
  currentStep?: TelegramBindStep
  userIds?: string[]
  onCallBack?: () => void
}
export interface IPostStep {
  name: 'post-step'
}

export interface IVisible {
  name: 'visible'
  visible: 'Public' | 'Circle'
  setVisible: (visible: string) => void
  visibleRange: VisibleRangeType
  setVisibleRange: (value: VisibleRangeType) => void
}

export interface CreateOrEditListModal {
  name: 'create-or-edit-list'
  purpose?: string
  list?: AppBskyGraphDefs.ListView
  onSave?: (uri: string) => void
}

export interface UserAddRemoveListsModal {
  name: 'user-add-remove-lists'
  subject: string
  handle: string
  displayName: string
  onAdd?: (listUri: string) => void
  onRemove?: (listUri: string) => void
}

export interface ListAddRemoveUsersModal {
  name: 'list-add-remove-users'
  list: AppBskyGraphDefs.ListView
  onChange?: (
    type: 'add' | 'remove',
    profile: AppBskyActorDefs.ProfileViewBasic,
  ) => void
}

export interface EditImageModal {
  name: 'edit-image'
  image: ImageModel
  gallery: GalleryModel
}

export interface CropImageModal {
  name: 'crop-image'
  uri: string
  dimensions?: {width: number; height: number}
  onSelect: (img?: RNImage) => void
}

export interface AltTextImageModal {
  name: 'alt-text-image'
  image: ImageModel
}

export interface DeleteAccountModal {
  name: 'delete-account'
}

export interface SelfLabelModal {
  name: 'self-label'
  labels: string[]
  hasMedia: boolean
  onChange: (labels: string[]) => void
}

export interface ChangeHandleModal {
  name: 'change-handle'
  onChanged: () => void
}

export interface WaitlistModal {
  name: 'waitlist'
}

export interface InviteCodesModal {
  name: 'invite-codes'
}

export interface AddAppPasswordModal {
  name: 'add-app-password'
}

export interface IShareProfile {
  name: 'share-profile'
  profile: {
    handle: string
    displayName: string
    avatar: string
    description: string
  }
}
export interface IShareTea {
  name: 'share-tea'
  avatar: string
  displayName: string
  url: string
  image: string
  content: string
  likeCount: number
  repostCount: number
  replyCount: number
  target: string
}

export interface ContentLanguagesSettingsModal {
  name: 'content-languages-settings'
}

export interface PostLanguagesSettingsModal {
  name: 'post-languages-settings'
}

export interface VerifyEmailModal {
  name: 'verify-email'
  showReminder?: boolean
  onSuccess?: () => void
}

export interface ChangeEmailModal {
  name: 'change-email'
}

export interface ChangePasswordModal {
  name: 'change-password'
}
export interface PostTargetModal {
  name: 'post-target'
}

export interface LinkWarningModal {
  name: 'link-warning'
  text: string
  href: string
  share?: boolean
}

export interface InAppBrowserConsentModal {
  name: 'in-app-browser-consent'
  href: string
}

export interface ConnnectWalletModal {
  name: 'connect-wallet'
  type: 'connect' | 'link'
}

export interface StakeDonateModal extends StakeDonateModalProps {
  name: 'stake-donate'
}

export interface TranstionResultModal extends TransResultModalProps {
  name: 'trans-result'
}

export interface BetModal extends BetToEarnModalProps {
  name: 'earn-bet'
}

export interface SetBetModal extends BetToEarnModalProps {
  name: 'set-bet'
}

export interface DetectLinkedWalletModal {
  name: 'detect-link-wallet'
}

export interface WithdrawModal extends WithdrawModalType {
  name: 'withdraw'
}

export interface WithdrawPreviewModal extends WithdrawPreviewModalProps {
  name: 'withdraw-preview'
}

export interface SwitchMaskModal {
  name: 'switch-mask'
}

export interface PostSelectCircleModal extends PostSelectCircleModalProps {
  name: 'post-select-cricle'
}

export interface SelectUnvisibleModal extends SelectUnvisibleModalProps {
  name: 'post-select-unvisible'
}

export type Modal =
  // Account
  | AddAppPasswordModal
  | ChangeHandleModal
  | DeleteAccountModal
  | EditProfileModal
  | VerifyEmailModal
  | ChangeEmailModal
  | ChangePasswordModal
  | ITelegramModal
  | ILinkSocialModal
  | IShareProfile
  | IShareTea

  // Curation
  | ContentLanguagesSettingsModal
  | PostLanguagesSettingsModal

  // Lists
  | CreateOrEditListModal
  | UserAddRemoveListsModal
  | ListAddRemoveUsersModal

  // Posts
  | AltTextImageModal
  | CropImageModal
  | EditImageModal
  | SelfLabelModal
  | PostTargetModal
  | IPostStep
  | IVisible

  // Sipz access
  | WaitlistModal
  | InviteCodesModal

  // Generic
  | LinkWarningModal
  | InAppBrowserConsentModal
  | SwitchMaskModal
  | PostSelectCircleModal
  | SelectUnvisibleModal

  // Web3
  | ConnnectWalletModal
  | StakeDonateModal
  | TranstionResultModal
  | BetModal
  | SetBetModal
  | DetectLinkedWalletModal
  | WithdrawModal
  | WithdrawPreviewModal

const ModalContext = React.createContext<{
  isModalActive: boolean
  activeModals: Modal[]
}>({
  isModalActive: false,
  activeModals: [],
})

const ModalControlContext = React.createContext<{
  openModal: (modal: Modal) => void
  closeModal: () => boolean
  closeAllModals: () => boolean
}>({
  openModal: () => {},
  closeModal: () => false,
  closeAllModals: () => false,
})

/**
 * @deprecated DO NOT USE THIS unless you have no other choice.
 */
export let unstable__openModal: (modal: Modal) => void = () => {
  throw new Error(`ModalContext is not initialized`)
}

/**
 * @deprecated DO NOT USE THIS unless you have no other choice.
 */
export let unstable__closeModal: () => boolean = () => {
  throw new Error(`ModalContext is not initialized`)
}

export function Provider({children}: React.PropsWithChildren<{}>) {
  const [activeModals, setActiveModals] = React.useState<Modal[]>([])

  const openModal = useNonReactiveCallback((modal: Modal) => {
    setActiveModals(modals => [...modals, modal])
  })

  const closeModal = useNonReactiveCallback(() => {
    let wasActive = activeModals.length > 0
    setActiveModals(modals => {
      return modals.slice(0, -1)
    })
    return wasActive
  })

  const closeAllModals = useNonReactiveCallback(() => {
    let wasActive = activeModals.length > 0
    setActiveModals([])
    return wasActive
  })

  unstable__openModal = openModal
  unstable__closeModal = closeModal

  const state = React.useMemo(
    () => ({
      isModalActive: activeModals.length > 0,
      activeModals,
    }),
    [activeModals],
  )

  const methods = React.useMemo(
    () => ({
      openModal,
      closeModal,
      closeAllModals,
    }),
    [openModal, closeModal, closeAllModals],
  )

  return (
    <ModalContext.Provider value={state}>
      <ModalControlContext.Provider value={methods}>
        {children}
      </ModalControlContext.Provider>
    </ModalContext.Provider>
  )
}

export function useModals() {
  return React.useContext(ModalContext)
}

export function useModalControls() {
  return React.useContext(ModalControlContext)
}
