import React, {useMemo} from 'react'
import {Text, TouchableOpacity, View} from 'react-native'
import {FinEntity} from '@atproto/api/dist/client/types/app/bsky/feed/post'
import {Trans} from '@lingui/macro'

import {CloseGrayIcon, CloseIcon} from '#/view/icons/ModalIcons'
import {useTheme} from '#/alf'
import {EllipsisBox} from '../composer/postBox/EllipsisContainer'
import {UserAvatar} from '../util/UserAvatar'

export function TargetDisplay({
  targets,
  previewMode = false,
}: {
  targets: FinEntity[]
  previewMode?: boolean
}) {
  if (previewMode) {
    return (
      <>
        <EllipsisBox
          array={targets}
          keyName="did"
          height={60}
          gap={2}
          isTarget
          renderItem={tar => (
            <Capsule name={tar.name} avatar={tar.avatar} type={tar.type} />
          )}
        />
        <View style={{height: 5, width: 1}} />
      </>
    )
  }
  return (
    <View
      style={{
        flexDirection: 'row',
        flexWrap: 'wrap',
        overflow: 'hidden',
      }}>
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          paddingBottom: 5,
          marginRight: 10,
        }}>
        <Text style={{color: '#979797', fontSize: 12, fontWeight: '700'}}>
          <Trans>Spill about:</Trans>
        </Text>
      </View>
      {targets.map((tar, index) => {
        return (
          <Capsule
            key={String(index)}
            name={tar.name || ''}
            avatar={tar.avatar || ''}
            type={tar.type as 'person'}
          />
        )
      })}
    </View>
  )
}

interface ICapsuleProps {
  name: string
  avatar: string
  key?: string
  handle?: string
  type?: 'organization' | 'person' | 'guarantors'
  onDelete?: () => void
}

export function Capsule({
  key,
  name,
  type,
  avatar,
  onDelete,
  handle,
}: ICapsuleProps) {
  const t = useTheme()

  const config = useMemo(() => {
    const styleConfig = {
      background: '#F0DDFF',
      height: 32,
      borderWidth: 1,
      avatarSize: 24,
    }
    if (type !== 'organization' && type !== 'person') return styleConfig

    styleConfig.avatarSize = 16
    styleConfig.height = 24
    styleConfig.borderWidth = 0
    styleConfig.background = type === 'organization' ? '#E8E8E8' : '#FFF3BD'
    return styleConfig
  }, [type])

  return (
    <View
      key={key}
      style={{
        flexDirection: 'row',
        height: config.height,
        padding: 2,
        paddingRight: onDelete ? 5 : 10,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: t.atoms.bg_capsule.backgroundColor, //config.background,
        borderRadius: 18,
        marginRight: 10,
        marginBottom: 5,
        borderWidth: config.borderWidth,
        borderColor: t.palette.primary,
        maxWidth: '100%',
      }}>
      <UserAvatar
        avatar={avatar}
        type={avatar ? 'user' : (type as 'organization')}
        // size={config.avatarSize}
        size={20}
      />
      <View>
        <Text
          style={{
            color: t.atoms.text_capsule.color, //'#5E6272',
            fontSize: 12,
            marginLeft: 6,
          }}>
          {name}
        </Text>
        {handle && (
          <Text
            style={{
              color: 'white',
              fontSize: 12,
              marginLeft: 6,
            }}>
            {handle}
          </Text>
        )}
      </View>
      {onDelete && (
        <TouchableOpacity
          accessibilityRole="button"
          style={{
            marginLeft: 5,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={onDelete}>
          {type !== 'guarantors' ? (
            <CloseIcon circleColor={'#000'} />
          ) : (
            <CloseGrayIcon />
          )}
        </TouchableOpacity>
      )}
    </View>
  )
}
