import React from 'react'
import {Text, View} from 'react-native'

import {atoms as a, useTheme} from '#/alf'
import {MemberProps} from '.'

type Props = {
  count?: number
  members?: MemberProps[]
}
export function CircleMembers({count}: Props) {
  const t = useTheme()
  return (
    <View>
      {count && count > 0 ? (
        <Text style={[a.text_xs, t.atoms.text_contrast_low]}>
          {count} Members
        </Text>
      ) : null}
    </View>
  )
}
