import React from 'react'
import {findNodeHandle, View} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useQueryClient} from '@tanstack/react-query'

import {isNative} from '#/platform/detection'
import {FeedDescriptor} from '#/state/queries/post-feed'
import {RQKEY as FEED_RQKEY} from '#/state/queries/post-feed'
import {truncateAndInvalidate} from '#/state/queries/util'
import {usePalette} from 'lib/hooks/usePalette'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {Text} from '#/view/com/util/text/Text'
import {Feed} from 'view/com/posts/Feed'
import {ListRef} from 'view/com/util/List'
import {LoadLatestBtn} from 'view/com/util/load-latest/LoadLatestBtn'
import {SectionRef} from './types'

interface FeedSectionProps {
  feed: FeedDescriptor
  headerHeight: number
  isFocused: boolean
  scrollElRef: ListRef
  ignoreFilterFor?: string
  isMe?: boolean
  setScrollViewTag: (tag: number | null) => void
}
export const ProfileFeedSection = React.forwardRef<
  SectionRef,
  FeedSectionProps
>(function FeedSectionImpl(
  {
    feed,
    isMe,
    headerHeight,
    isFocused,
    scrollElRef,
    ignoreFilterFor,
    setScrollViewTag,
  },
  ref,
) {
  const {_} = useLingui()
  const queryClient = useQueryClient()
  const [hasNew, setHasNew] = React.useState(false)
  const [isScrolledDown, setIsScrolledDown] = React.useState(false)

  const onScrollToTop = React.useCallback(() => {
    scrollElRef.current?.scrollToOffset({
      animated: isNative,
      offset: -headerHeight,
    })
    truncateAndInvalidate(queryClient, FEED_RQKEY(feed))
    setHasNew(false)
  }, [scrollElRef, headerHeight, queryClient, feed, setHasNew])
  React.useImperativeHandle(ref, () => ({
    scrollToTop: onScrollToTop,
  }))

  const renderPostsEmpty = React.useCallback(
    (feed: string) => {
      let icon = 'tea'
      let tipMsg = isMe
        ? _(msg`Oops...You don't have even one cup of tea to serve?`)
        : _(msg`Hmm...No tea is served here`)
      if (feed.includes('likes')) {
        icon = 'heart'
        tipMsg = _(msg`Hmm...Seems not a cup of tea has attracted you?`)
      } else if (feed.includes('follow')) {
        icon = 'follow'
        tipMsg = _(msg`You are not following anyone`)
      } else if (feed.includes('engagement')) {
        icon = 'engagement'
        tipMsg = _(msg`Hmm...How about sipping some tea?`)
      }
      return <EmptyBox isMe={isMe} icon={icon} message={tipMsg} />
    },
    [_, isMe],
  )

  React.useEffect(() => {
    if (isFocused && scrollElRef.current) {
      const nativeTag = findNodeHandle(scrollElRef.current)
      setScrollViewTag(nativeTag)
    }
  }, [isFocused, scrollElRef, setScrollViewTag])

  return (
    <View>
      <Feed
        testID="postsFeed"
        enabled={isFocused}
        feed={feed}
        scrollElRef={scrollElRef}
        onHasNew={setHasNew}
        onScrolledDownChange={setIsScrolledDown}
        renderEmptyState={() => renderPostsEmpty(feed)}
        headerOffset={headerHeight}
        renderEndOfFeed={ProfileEndOfFeed}
        ignoreFilterFor={ignoreFilterFor}
        outsideHeaderOffset={headerHeight}
      />
      {(isScrolledDown || hasNew) && (
        <LoadLatestBtn
          onPress={onScrollToTop}
          label={_(msg`Load new posts`)}
          showIndicator={hasNew}
        />
      )}
    </View>
  )
})

function ProfileEndOfFeed() {
  const pal = usePalette('default')

  return (
    <View
      style={[
        pal.border,
        {paddingTop: 32, paddingBottom: 32, borderTopWidth: 1},
      ]}>
      <Text style={[pal.textLight, pal.border, {textAlign: 'center'}]}>
        <Trans>End of feed</Trans>
      </Text>
    </View>
  )
}
