import React, {useState} from 'react'
import {Text, View} from 'react-native'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {RightIcon} from '#/view/icons/ModalIcons'
import {useTagList} from '#/view/screens/Circle/hooks'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonText} from '#/components/Button'
import * as Dialog from '#/components/Dialog'
import {Close_Stroke2_Corner2_Rounded as Close} from '#/components/icons/Close'
import {FilterNormal_Stroke2_Corner0_Rounded as FilterNormal} from '#/components/icons/FilterNormal'
import {TelegramLogo} from '#/components/icons/StakeIcons'
import {H3} from '#/components/Typography'
import {EmptyBox} from '../util/EmptyBox'
import TagItem, {Tag} from './TagItem'

type Props = {
  defaultDisplayTags?: Tag[]
  horizontal?: boolean
  onSelected?: (tag: Tag) => void
  onConfirm?: (tag: Tag[]) => void
}

export default function TagFilter({
  // defaultDisplayTags,
  // horizontal = true,
  // onSelected,
  onConfirm,
}: Props) {
  const t = useTheme()
  const basic = Dialog.useDialogControl()
  const {isDesktop} = useWebMediaQueries()

  // const [tags, setTags] = useState<Tag[]>([])
  const {data: tags = []} = useTagList()
  const [selectedTags, setSelectedTags] = useState<Tag[]>([])

  const selectedHandle = (selected: boolean, tag: Tag) => {
    if (selected) {
      setSelectedTags(s => [...s, tag])
    } else {
      setSelectedTags(selectedTags.filter(t => t.id !== tag.id))
    }
  }

  let content
  if (tags.length === 0) {
    content = (
      <View style={[a.align_center, a.pb_lg]}>
        <EmptyBox
          icon="notifications"
          message={'there is no tags'}
          viewStyles={{paddingTop: 0}}
        />
      </View>
    )
  } else {
    content = (
      <View style={[a.flex_1, a.flex_wrap]}>
        <View style={[a.flex_row, a.gap_md]}>
          <TagItem
            key={'all'}
            tag={{
              id: -1,
              name: 'All',
              selected: false,
            }}
            // showCheckbox
            selected={!!selectedTags.find(item => item.name === 'All')}
            onSelected={selectedHandle}
          />
          {tags.map(tag => (
            <TagItem
              key={tag.name}
              tag={tag}
              // showCheckbox
              selected={!!selectedTags.find(item => item.name === tag.name)}
              onSelected={selectedHandle}
            />
          ))}
        </View>
      </View>
    )
  }

  return (
    <>
      <View
        style={[
          a.flex_row,
          a.align_center,
          a.justify_between,
          a.py_md,
          a.gap_md,
        ]}>
        {/* <ScrollView horizontal={horizontal}> */}
        <View
          style={[
            a.flex_row,
            a.flex_1,
            a.align_center,
            a.justify_between,
            a.px_md,
            t.atoms.input_border,
            t.atoms.input_bg,
            {height: 40, borderRadius: 10, borderWidth: 1},
          ]}>
          {/* {defaultDisplayTags?.map(t => (
              <TagItem key={t.id} tag={t} onSelected={() => onSelected?.(t)} />
            ))} */}
          <View style={[a.flex_row, a.align_center, a.gap_md]}>
            <TelegramLogo size={20} />
            <Text style={[t.atoms.text, a.font_semibold, a.text_sm]}>
              Telegram
            </Text>
          </View>
          {/* <RightIcon color={t.palette.gray_10} /> */}
        </View>
        {/* </ScrollView> */}
        <Button
          label="filter"
          variant="outline"
          style={[
            a.align_center,
            a.justify_center,
            a.flex_1,
            t.atoms.input_border,
            t.atoms.input_bg,
            {height: 40, borderRadius: 10, borderWidth: 1},
            selectedTags?.length > 0 && {borderColor: t.palette.primary_active},
          ]}
          onPress={() => basic.open()}>
          <View
            style={[
              a.flex_1,
              a.flex_row,
              a.align_center,
              a.justify_between,
              a.px_md,
            ]}>
            <View style={[a.flex_row, a.align_center, a.gap_md]}>
              <View
                style={[
                  {width: 20, height: 20, borderRadius: 10},
                  t.atoms.bg_capsule,
                  a.align_center,
                  a.justify_center,
                  selectedTags?.length > 0 && {
                    backgroundColor: t.palette.primary_active,
                  },
                ]}>
                <FilterNormal
                  size="sm"
                  style={[
                    {marginLeft: 2, marginTop: 2, color: t.atoms.text.color},
                  ]}
                />
              </View>
              <Text
                style={[
                  t.atoms.text,
                  a.font_semibold,
                  a.text_sm,
                  selectedTags?.length > 0 && {
                    color: t.palette.primary_active,
                  },
                ]}>
                Filter{selectedTags?.length > 0 ? '...' : ''}
              </Text>
            </View>
            <RightIcon
              color={
                selectedTags?.length > 0
                  ? t.palette.primary_active
                  : t.palette.gray_10
              }
            />
          </View>
        </Button>
      </View>
      <Dialog.Outer control={basic}>
        <Dialog.Inner
          label="test"
          style={[{minHeight: 360}, a.justify_between, a.flex_col, a.p_lg]}>
          <View
            style={[a.flex_row, a.align_center, a.justify_between, a.pb_3xl]}>
            <Button
              label={'close'}
              style={[a.p_sm]}
              onPress={() => basic.close()}>
              <Close fill={t.atoms.text.color} />
              {/* <ButtonIcon icon={Close} /> */}
            </Button>
            <H3
              nativeID="dialog-title"
              style={[a.font_semibold, a.text_center, {fontSize: 17}]}>
              Tag Filter
            </H3>
            {/* <Button
              style={[a.p_md, {width: 50}]}
              color="secondary"
              onPress={() => setCreateStatus('open')}
              label="Create"
              variant="solid"
              shape="square"
              size="tiny">
              <ButtonText>Create</ButtonText>
            </Button> */}
            <View />
          </View>
          {content}
          {/* {selectedTags.length > 0 && ( */}
          <View style={[a.gap_md, isDesktop ? a.flex_row_reverse : {}]}>
            <Button
              style={[a.p_md, isDesktop ? a.flex_1 : {}]}
              color="primary"
              onPress={() => {
                onConfirm?.(
                  selectedTags?.findIndex(o => o?.id === -1) >= 0
                    ? []
                    : selectedTags,
                )
                basic.close()
              }}
              label="Confirm"
              variant="solid"
              size="large">
              <ButtonText>Confirm</ButtonText>
            </Button>
            <Button
              style={[a.p_md, isDesktop ? a.flex_1 : {}]}
              color="secondary"
              onPress={() => {
                onConfirm?.([])
                setSelectedTags([])
                basic.close()
              }}
              label="reset"
              variant="solid"
              size="large">
              <ButtonText>Reset</ButtonText>
            </Button>
          </View>
          {/* )} */}
        </Dialog.Inner>
      </Dialog.Outer>
      {/* <TagCreate status={createStatus} updateTags={updateTags} /> */}
    </>
  )
}
