import React from 'react'
import {SafeAreaView, ScrollView, StyleSheet, Text, View} from 'react-native'
import {StackActions, useNavigation} from '@react-navigation/native'

import {emitSoftReset} from '#/state/events'
import {useModalControls} from '#/state/modals'
import {useSession} from '#/state/session'
import {useSetDrawerOpen} from '#/state/shell'
import {useAnalytics} from 'lib/analytics/analytics'
import {useNavigationTabState} from 'lib/hooks/useNavigationTabState'
import {usePalette} from 'lib/hooks/usePalette'
import {getTabState, TabState} from 'lib/routes/helpers'
import {NavigationProp} from 'lib/routes/types'
import {s} from 'lib/styles'
import {useTheme} from 'lib/ThemeContext'
import {isWeb} from 'platform/detection'
import {TelegramBindStep} from '#/view/com/modals/TelegramModal/config'
import {useTelegramLink} from '#/view/com/modals/TelegramModal/hooks'
import {useProfileStore} from '#/view/com/util/sdlStore/ProfileStore'
// import {useTgStore} from '#/view/com/util/sdlStore/TgStore'
import {NavSignupCard} from '#/view/shell/NavSignupCard'
import {useTheme as useAlfTheme} from '#/alf'
import DrawerProfileCard from './DrawerProfileCard'
import {
  // AssetsMenuItem,
  BookmarksMenuItem,
  CircleMenuItem,
  HomeMenuItem,
  InviteMenuItem,
  MaskMenuItem,
  // MessageMenuItem,
  NotificationsMenuItem,
  ProfileMenuItem,
  SettingsMenuItem,
} from './MenuItems'

let DrawerContent = ({}: {}): React.ReactNode => {
  const theme = useTheme()
  const t = useAlfTheme()
  const pal = usePalette('default')
  const setDrawerOpen = useSetDrawerOpen()
  const navigation = useNavigation<NavigationProp>()
  const {track} = useAnalytics()
  const {
    // isAtHome,
    isAtSearch,
    // isAtFeeds,
    // isAtNotifications,
    // isAtMyProfile
  } = useNavigationTabState()
  const {hasSession, currentAccount} = useSession()
  // const {bind: hasBindTelegram} = useTgStore()
  const {openModal} = useModalControls()
  const {bind: hasBindTelegram} = useTelegramLink()

  // events
  // =

  const {setTabName} = useProfileStore()

  const onPressTab = React.useCallback(
    (tab: string) => {
      track('Menu:ItemClicked', {url: tab})
      const state = navigation.getState()
      setDrawerOpen(false)
      if (tab === 'Bookmarks') {
        setTabName('Bookmarks')
      }
      if (isWeb) {
        // hack because we have flat navigator for web and MyProfile does not exist on the web navigator -ansh
        if (tab === 'MyProfile') {
          navigation.navigate('Profile', {name: currentAccount!.handle})
        } else if (tab === 'Bookmarks') {
          navigation.navigate('Profile', {
            name: currentAccount!.handle,
            tabName: 'Bookmarks',
          })
        } else {
          // @ts-ignore must be Home, Search, Notifications, or MyProfile
          navigation.navigate(tab)
        }
      } else {
        const tabState = getTabState(state, tab)
        if (tabState === TabState.InsideAtRoot) {
          emitSoftReset()
        } else if (tabState === TabState.Inside) {
          navigation.dispatch(StackActions.popToTop())
        } else {
          if (tab === 'Bookmarks') {
            navigation.navigate(`MyProfileTab`)
          } else {
            // @ts-ignore must be Home, Search, Notifications, or MyProfile
            navigation.navigate(`${tab}Tab`)
          }
        }
      }
      if (tab === 'Circle') {
        navigation.navigate('Circle')
      }
      if (tab === 'Mask') {
        navigation.navigate('Mask', {time: Date.now()})
      }
    },
    [track, navigation, setDrawerOpen, currentAccount, setTabName],
  )

  const onPressHome = React.useCallback(() => onPressTab('Home'), [onPressTab])

  // const onPressSearch = React.useCallback(
  //   () => onPressTab('Search'),
  //   [onPressTab],
  // )

  const noPressNotification = React.useCallback(
    () => onPressTab('Notifications'),
    [onPressTab],
  )

  const onPressProfile = React.useCallback(() => {
    onPressTab('MyProfile')
  }, [onPressTab])

  const onPressBookMark = React.useCallback(() => {
    onPressTab('Bookmarks')
  }, [onPressTab])

  const onPressCircle = React.useCallback(() => {
    if (hasBindTelegram) {
      onPressTab('Circle')
    } else {
      setDrawerOpen(false)
      openModal({name: 'telegram'})
    }
  }, [onPressTab, hasBindTelegram, openModal, setDrawerOpen])

  const onPressMask = React.useCallback(() => {
    onPressTab('Mask')
  }, [onPressTab])

  // const onPressMyFeeds = React.useCallback(() => {
  //   track('Menu:ItemClicked', {url: 'Feeds'})
  //   navigation.navigate('Feeds')
  //   setDrawerOpen(false)
  // }, [navigation, setDrawerOpen, track])

  // const onPressLists = React.useCallback(() => {
  //   track('Menu:ItemClicked', {url: 'Lists'})
  //   navigation.navigate('Lists')
  //   setDrawerOpen(false)
  // }, [navigation, track, setDrawerOpen])

  const onPressSettings = React.useCallback(() => {
    track('Menu:ItemClicked', {url: 'Settings'})
    navigation.navigate('Settings')
    setDrawerOpen(false)
  }, [navigation, track, setDrawerOpen])

  const onPressInvite = React.useCallback(() => {
    track('Menu:ItemClicked', {url: 'Invite'})
    setDrawerOpen(false)
    if (hasBindTelegram) {
      openModal({name: 'telegram', currentStep: TelegramBindStep.SelectContact})
    } else {
      openModal({name: 'telegram'})
    }
  }, [track, setDrawerOpen, openModal, hasBindTelegram])

  // const noPressMessage = React.useCallback(
  //   () => onPressTab('Messages'),
  //   [onPressTab],
  // )

  // const onPressFeedback = React.useCallback(() => {
  //   track('Menu:FeedbackClicked')
  //   Linking.openURL(
  //     FEEDBACK_FORM_URL({
  //       email: currentAccount?.email,
  //       handle: currentAccount?.handle,
  //     }),
  //   )
  // }, [track, currentAccount])

  // const onPressHelp = React.useCallback(() => {
  //   track('Menu:HelpClicked')
  //   Linking.openURL(HELP_DESK_URL)
  // }, [track])

  // const onPressAssets = React.useCallback(() => {
  //   track('Menu:ItemClicked', {url: 'Assets'})
  //   navigation.navigate('TransAssets')
  //   setDrawerOpen(false)
  // }, [navigation, track, setDrawerOpen])

  // rendering
  // =

  return (
    <View
      testID="drawer"
      style={[
        styles.view,
        {maxWidth: 300},
        theme.colorScheme === 'light'
          ? pal.view
          : {backgroundColor: t.palette.black},
      ]}>
      <SafeAreaView style={s.flex1}>
        <ScrollView style={styles.main}>
          {hasSession && currentAccount ? (
            <View style={{}}>
              <DrawerProfileCard
                account={currentAccount}
                onPressProfile={onPressProfile}
                closeDrawer={() => setDrawerOpen(false)}
              />
            </View>
          ) : (
            <View style={{paddingRight: 20}}>
              <NavSignupCard />
            </View>
          )}
          <HomeMenuItem isActive={isAtSearch} onPress={onPressHome} />
          {hasSession && (
            <>
              <ProfileMenuItem isActive={isAtSearch} onPress={onPressProfile} />
              <BookmarksMenuItem
                isActive={isAtSearch}
                onPress={onPressBookMark}
              />
              <CircleMenuItem isActive={isAtSearch} onPress={onPressCircle} />
              <NotificationsMenuItem
                isActive={isAtSearch}
                onPress={noPressNotification}
              />

              {/* <AssetsMenuItem isActive={isAtSearch} onPress={() => {}} /> */}
              {/* <View style={styles.container}>
                <View style={styles.box} />
              </View> */}
              <Text
                style={[
                  t.atoms.text_sub,
                  {
                    marginTop: 4,
                    paddingTop: 20,
                    borderTopWidth: 1,
                    borderColor: t.name === 'light' ? '#d9d9d9' : '#262627',
                  },
                ]}>
                General
              </Text>
              {/* <MessageMenuItem isActive={isAtSearch} onPress={noPressMessage} /> */}
              <MaskMenuItem isActive={isAtSearch} onPress={onPressMask} />
              <SettingsMenuItem
                isActive={isAtSearch}
                onPress={onPressSettings}
              />
              <InviteMenuItem isActive={isAtSearch} onPress={onPressInvite} />
            </>
          )}
        </ScrollView>
      </SafeAreaView>
    </View>
  )
}
DrawerContent = React.memo(DrawerContent)
export {DrawerContent}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: 10,
    paddingRight: 10,
  },
  box: {
    width: '100%',
    height: 38,
    borderTopWidth: 1,
    borderTopColor: '#d9d9d9',
  },
  view: {
    flex: 1,
    paddingBottom: 50,
  },
  main: {
    paddingHorizontal: 16,
    paddingTop: 20,
  },
})
