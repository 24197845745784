import React from 'react'
import Animated from 'react-native-reanimated'
import {useSafeAreaInsets} from 'react-native-safe-area-context'

import {useMinimalShellFooterTransform} from '#/lib/hooks/useMinimalShellTransform'
import {usePalette} from '#/lib/hooks/usePalette'
import {clamp} from '#/lib/numbers'
import {useShellLayout} from '#/state/shell/shell-layout'
import {isIOS, isWeb} from 'platform/detection'
import {Text} from '#/view/com/util/text/Text'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import {RelyIcon} from '#/components/icons/StakeIcons'

export function PostBottomBar({onPressReply}: {onPressReply: () => void}) {
  const t = useTheme()
  const pal = usePalette('default')
  const safeAreaInsets = useSafeAreaInsets()
  const footerMinimalShellTransform = useMinimalShellFooterTransform()
  const {footerHeight} = useShellLayout()
  return (
    <Animated.View
      style={[
        a.w_full,
        a.justify_center,
        a.px_lg,
        pal.view,
        pal.border,
        {
          paddingVertical: clamp(safeAreaInsets.bottom, 15, 30),
          borderTopWidth: 1,
          backgroundColor:
            t.name === 'light' ? 'rgba(255, 255, 255, 0.3)' : '#000',
        },
        isWeb ? {maxWidth: 598} : {},
        footerMinimalShellTransform,
      ]}
      onLayout={e => {
        footerHeight.value = e.nativeEvent.layout.height
      }}>
      <Button
        color="secondary"
        shape="default"
        variant="outline"
        label="Press to reply"
        style={[
          a.flex_1,
          a.align_center,
          a.justify_start,
          a.overflow_hidden,
          a.rounded_5xl,
          a.p_md,
          a.gap_sm,
          t.atoms.input_bg,
          t.atoms.input_border,
          isIOS ? {transform: [{translateY: -12}]} : {},
        ]}
        onPress={onPressReply}>
        <RelyIcon primaryColor={t.palette.gray_10} size={18} />
        <Text style={[a.text_sm, {color: t.palette.gray_10}]}>Reply</Text>
      </Button>
    </Animated.View>
  )
}
