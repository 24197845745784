const colors: string[][] = [
  ['#FF5736', '#FF5736'],
  ['#FF6B00', '#FF6B00'],
  ['#BB3BEE', '#BB3BEE'],
  ['#873EFF', '#873EFF'],
  ['#E829A1', '#E829A1'],
  ['#F63C69', '#F63C69'],
]
export interface ICircleItemConfig {
  index: number
  color: string[]
}

const CircleFeedMap = new Map<string, ICircleItemConfig>()

let index = 0

export function getCircleItemConfig(did: string): ICircleItemConfig {
  if (CircleFeedMap.has(did)) {
    return CircleFeedMap.get(did)!
  }
  const color: string[] = colors[index++ % colors.length]
  CircleFeedMap.set(did, {index, color})
  return {index, color}
}
