import React, {useState} from 'react'
import {/*Image, ImageBackground, Pressable, Text,*/ View} from 'react-native'
import {AppBskyFeedDefs} from '@atproto/api'

// import {usePalette} from '#/lib/hooks/usePalette'
import {Shadow} from '#/state/cache/post-shadow'
import {useBetTransSummary} from '#/state/queries/transaction-bet'
// import {atoms as a, useTheme} from '#/alf'
// import {EditIcon} from '#/components/icons/StakeIcons'
// import {TabBar} from '../pager/TabBar'
import {PostBetDetail} from '../post-transaction/BetTabPanel'
import {PostChallenge} from '../post-transaction/ChallengeTabPanel'

// const challengeTitle = require('../../../../assets/imgs/chanllenge_title.png')
// const postChallengeBg = require('../../../../assets/imgs/post_challenge_tab_bg.png')

export function PostBetChallenge({
  post,
}: {
  post: Shadow<AppBskyFeedDefs.PostView>
}) {
  // const t = useTheme()
  // const items = ['Bet', 'Challenge']
  // const pal = usePalette('default')
  const {data: betSummary} = useBetTransSummary(post.uri)
  const hasBet = betSummary?.judge_mode

  const [currentItem] = useState<number>(0)

  return (
    <View>
      {
        hasBet ? (
          <View>
            {/* <View
              style={[
                a.border_t,
                a.border_b,
                a.mb_xl,
                {borderColor: t.palette.contrast_100},
              ]}>
              <TabBar
                key={items?.join(',')}
                onPressSelected={setCurrentItem}
                selectedPage={currentItem}
                onSelect={setCurrentItem}
                testID={'postBetChallengeTab'}
                items={items}
                indicatorColor={pal.colors.link}
                isCentered={true}
                selectedTextStyle={[
                  a.text_md,
                  a.font_bold,
                  {color: t.palette.primary},
                ]}
                textStyle={[
                  a.text_sm,
                  a.font_normal,
                  {color: t.palette.black_25},
                ]}
              />
            </View> */}
            {currentItem === 0 && betSummary && (
              <PostBetDetail post={post} betSummary={betSummary} />
            )}
            {currentItem === 1 && <PostChallenge post={post} />}
          </View>
        ) : (
          <></>
        ) /*(
        <View
          style={[
            a.p_lg,
            a.mt_md,
            {backgroundColor: t.palette.white, minHeight: 110},
          ]}>
          <ImageBackground
            source={postChallengeBg}
            resizeMode="cover"
            imageStyle={{borderRadius: 10}}
            style={[a.flex_1, {borderRadius: 10}]}>
            <View
              style={[a.p_lg, a.flex_row, a.justify_between, a.align_center]}>
              <View style={[a.flex_1]}>
                <Image
                  source={challengeTitle}
                  style={[{width: 93, height: 33}]}
                  accessibilityIgnoresInvertColors
                />
                <Text style={[a.text_sm, a.font_semibold]} numberOfLines={2}>
                  Question or present evidence to force the spiller to respond.
                </Text>
              </View>
              <Pressable accessibilityRole="button" onPress={onPressEdit}>
                <EditIcon />
              </Pressable>
            </View>
          </ImageBackground>
        </View>
      )*/
      }
    </View>
  )
}
