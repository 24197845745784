import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {
  LayoutChangeEvent,
  ScrollView,
  StyleProp,
  StyleSheet,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'

import {isNative} from '#/platform/detection'
import {useProfileQuery} from '#/state/queries/profile'
import {useSession} from '#/state/session'
import {useSetDrawerOpen} from '#/state/shell'
import {usePalette} from 'lib/hooks/usePalette'
import {useWebMediaQueries} from 'lib/hooks/useWebMediaQueries'
import {LogoVisitorHorizontal} from '#/view/icons/LogoVisitorHorizontal'
import {MenuIcon} from '#/view/icons/TopBarIcons'
import {useTheme} from '#/alf'
import {atoms as a} from '#/alf'
import {PressableWithHover} from '../util/PressableWithHover'
import {Text} from '../util/text/Text'
import {UserAvatar} from '../util/UserAvatar'
import {DraggableScrollView} from './DraggableScrollView'

export interface TabBarProps {
  testID?: string
  selectedPage: number
  items: string[]
  indicatorColor?: string
  textStyle?: StyleProp<TextStyle>
  selectedTextStyle?: StyleProp<TextStyle>
  style?: StyleProp<ViewStyle>
  bgColor?: string
  hideBorder?: boolean
  onSelect?: (index: number) => void
  onPressSelected?: (index: number) => void
  hasMenu?: boolean
  isCentered?: boolean
  isBold?: boolean
  rightView?: JSX.Element
}

// How much of the previous/next item we're showing
// to give the user a hint there's more to scroll.
const OFFSCREEN_ITEM_WIDTH = 20

export function TabBar({
  testID,
  selectedPage,
  items,
  style,
  indicatorColor,
  textStyle,
  selectedTextStyle,
  bgColor,
  hideBorder,
  onSelect,
  onPressSelected,
  hasMenu,
  isCentered,
  isBold = true,
  rightView,
}: TabBarProps) {
  const pal = usePalette('default')
  const scrollElRef = useRef<ScrollView>(null)
  const itemRefs = useRef<Array<Element>>([])
  const [itemXs, setItemXs] = useState<number[]>([])
  const indicatorStyle = useMemo(
    () => ({borderBottomColor: indicatorColor || pal.colors.link}),
    [indicatorColor, pal],
  )
  const {isDesktop, isTablet, isTabletOrMobile} = useWebMediaQueries()
  const styles = isDesktop || isTablet ? desktopStyles : mobileStyles
  const setDrawerOpen = useSetDrawerOpen()
  const {hasSession, currentAccount} = useSession()
  const {data: profile} = useProfileQuery({did: currentAccount?.did})

  useEffect(() => {
    if (isNative) {
      // On native, the primary interaction is swiping.
      // We adjust the scroll little by little on every tab change.
      // Scroll into view but keep the end of the previous item visible.
      let x = itemXs[selectedPage] || 0
      x = Math.max(0, x - OFFSCREEN_ITEM_WIDTH)
      scrollElRef.current?.scrollTo({x})
    } else {
      // On the web, the primary interaction is tapping.
      // Scrolling under tap feels disorienting so only adjust the scroll offset
      // when tapping on an item out of view--and we adjust by almost an entire page.
      const parent = scrollElRef?.current?.getScrollableNode?.()
      if (!parent) {
        return
      }
      const parentRect = parent.getBoundingClientRect()
      if (!parentRect) {
        return
      }
      const {
        left: parentLeft,
        right: parentRight,
        width: parentWidth,
      } = parentRect
      const child = itemRefs.current[selectedPage]
      if (!child) {
        return
      }
      const childRect = child.getBoundingClientRect?.()
      if (!childRect) {
        return
      }
      const {left: childLeft, right: childRight, width: childWidth} = childRect
      let dx = 0
      if (childRight >= parentRight) {
        dx += childRight - parentRight
        dx += parentWidth - childWidth - OFFSCREEN_ITEM_WIDTH
      } else if (childLeft <= parentLeft) {
        dx -= parentLeft - childLeft
        dx -= parentWidth - childWidth - OFFSCREEN_ITEM_WIDTH
      }
      let x = parent.scrollLeft + dx
      x = Math.max(0, x)
      x = Math.min(x, parent.scrollWidth - parentWidth)
      if (dx !== 0) {
        parent.scroll({
          left: x,
          behavior: 'smooth',
        })
      }
    }
  }, [scrollElRef, itemXs, selectedPage, styles])

  const onPressItem = useCallback(
    (index: number) => {
      onSelect?.(index)
      if (index === selectedPage) {
        onPressSelected?.(index)
      }
    },
    [onSelect, selectedPage, onPressSelected],
  )

  // calculates the x position of each item on mount and on layout change
  const onItemLayout = React.useCallback(
    (e: LayoutChangeEvent, index: number) => {
      const x = e.nativeEvent.layout.x
      setItemXs(prev => {
        const Xs = [...prev]
        Xs[index] = x
        return Xs
      })
    },
    [],
  )

  const t = useTheme()

  const type = isBold
    ? isDesktop || isTablet
      ? 'xl-bold'
      : 'lg-bold'
    : undefined

  return (
    <View
      testID={testID}
      style={[
        pal.view,
        styles.outer,
        isCentered &&
          (bgColor
            ? {backgroundColor: bgColor, position: 'relative'}
            : {position: 'relative'}),
        style,
      ]}>
      <DraggableScrollView
        testID={`${testID}-selector`}
        horizontal={true}
        showsHorizontalScrollIndicator={false}
        ref={scrollElRef}
        contentContainerStyle={[
          styles.contentContainer,
          hasMenu && styles.menuContentContainer,
          isCentered ? {width: '100%'} : {},
        ]}>
        {items.map((item, i) => {
          const selected = i === selectedPage
          return (
            <PressableWithHover
              testID={`${testID}-selector-${i}`}
              key={`${item}-${i}`}
              ref={node => (itemRefs.current[i] = node)}
              onLayout={e => onItemLayout(e, i)}
              style={[
                !hasSession && isTabletOrMobile ? {padding: 2} : styles.item,
                isCentered && {alignItems: 'center', flex: 1},
              ]}
              hoverStyle={[!isCentered && pal.viewLight]}
              onPress={() => onPressItem(i)}>
              <View
                style={[
                  !hasSession && isTabletOrMobile
                    ? {paddingBottom: 0}
                    : styles.itemInner,
                  !isCentered && {borderBottomWidth: 3},
                  selected &&
                    (isCentered
                      ? {borderBottomColor: '#9674dd'}
                      : indicatorStyle),
                ]}>
                <Text
                  type={type}
                  testID={testID ? `${testID}-${item}` : undefined}
                  // style={[
                  //   selected ? pal.text : pal.textLight,
                  style={[
                    isCentered
                      ? selected
                        ? selectedTextStyle
                          ? selectedTextStyle
                          : {...pal.text, ...t.atoms.text}
                        : textStyle
                        ? textStyle
                        : t.atoms.text
                      : selected
                      ? selectedTextStyle
                        ? selectedTextStyle
                        : pal.text
                      : t.atoms.text,
                    {lineHeight: 20},
                    // t.atoms.text,
                  ]}>
                  {!hasSession && isTabletOrMobile ? (
                    <LogoVisitorHorizontal
                      width={90}
                      height={30}
                      testID="logoVisitorHorizontal"
                      style={{marginRight: 47}}
                    />
                  ) : (
                    item
                  )}
                </Text>
              </View>
              {isCentered && hasSession && (
                <View style={[styles.activeItem, selected && indicatorStyle]} />
              )}
            </PressableWithHover>
          )
        })}
      </DraggableScrollView>
      {hasMenu && (
        <TouchableOpacity
          accessibilityRole="button"
          style={styles.menu}
          onPress={() => setDrawerOpen(true)}>
          {!hasSession ? (
            <MenuIcon />
          ) : (
            <UserAvatar
              size={32}
              avatar={profile?.avatar}
              usePlainRNImage={true}
              type={profile?.associated?.labeler ? 'labeler' : 'user'}
            />
          )}
        </TouchableOpacity>
      )}
      {hideBorder ? (
        <></>
      ) : (
        <View style={[pal.border, styles.outerBottomBorder]} />
      )}
      {rightView && (
        <View style={[a.flex_1, a.align_end, a.justify_center]}>
          {rightView}
        </View>
      )}
    </View>
  )
}

const desktopStyles = StyleSheet.create({
  menu: {
    position: 'absolute',
    left: 5,
    top: 10,
    height: '100%',
  },
  outer: {
    flexDirection: 'row',
    width: '100%',
  },
  contentContainer: {
    paddingHorizontal: 0,
    backgroundColor: 'transparent',
  },
  menuContentContainer: {
    paddingLeft: 50,
  },
  item: {
    paddingTop: 14,
    paddingHorizontal: 14,
    justifyContent: 'center',
  },
  itemInner: {
    paddingBottom: 12,
    borderBottomColor: 'transparent',
  },
  activeItem: {
    width: 30,
    borderBottomWidth: 3,
    borderBottomColor: 'transparent',
  },
  outerBottomBorder: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '100%',
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
})

const mobileStyles = StyleSheet.create({
  menu: {
    position: 'absolute',
    left: 15,
    top: 6,
    height: '100%',
  },
  outer: {
    flexDirection: 'row',
  },
  contentContainer: {
    backgroundColor: 'transparent',
    paddingHorizontal: 6,
    width: '100%',
  },
  menuContentContainer: {
    paddingLeft: 50,
  },
  item: {
    paddingTop: 10,
    paddingHorizontal: 10,
    justifyContent: 'center',
  },
  itemInner: {
    paddingBottom: 10,
    borderBottomColor: 'transparent',
  },
  activeItem: {
    width: 30,
    borderBottomWidth: 3,
    borderBottomColor: 'transparent',
  },
  outerBottomBorder: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '100%',
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
})
