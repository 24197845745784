import React, {useMemo} from 'react'
import {Text, View} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {Trans} from '@lingui/macro'
import {useNavigation} from '@react-navigation/native'

import {NavigationProp} from '#/lib/routes/types'
import {EmptyBox} from '#/view/com/util/EmptyBox'
// import {useAgent} from '#/state/session'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import {ChevronRight_Stroke2_Corner0_Rounded as Chevron} from '#/components/icons/Chevron'
// import {MessageNotif} from '#/components/icons/MessageNotif'
import {Loader} from '#/components/Loader'
import {CircleItemProps} from '.'
import {CircleList} from './CircleList'
import {useCircleList} from './hooks'

type Props = {
  query?: string
}
export function CirclePanel({query}: Props) {
  const t = useTheme()
  const navigation = useNavigation<NavigationProp>()
  // const agent = useAgent()
  const {data: circles, isLoading} = useCircleList()
  // const [requestCount, setRequestCount] = useState<number>(0)

  const handlerEdit = (circle: CircleItemProps) => {
    navigation.navigate('CircleUpdate', {id: circle.id})
  }
  const handlerInvite = (circle: CircleItemProps) => {
    navigation.navigate('CircleInvite', {id: circle.id})
  }

  const filter = useMemo(() => {
    if (query) {
      return circles?.filter(circle =>
        circle.name?.toLowerCase().includes(query.toLowerCase()),
      )
    }
    return circles
  }, [query, circles])

  // useEffect(() => {
  //   agent.com.atproto.server.circleQueryApproveNum().then(res => {
  //     setRequestCount(res.data.count || 0)
  //   })
  // }, [agent.com.atproto.server])

  return (
    <View style={[a.p_xl, a.gap_md]}>
      <Button
        color="secondary"
        variant="outline"
        size="medium"
        label="Create Circle"
        style={[t.atoms.input_border, t.atoms.input_bg]}
        onPress={() => {
          navigation.navigate('CircleCreate')
        }}>
        <View style={[a.flex_1, a.flex_row, a.gap_md]}>
          <FontAwesomeIcon icon="plus" style={{color: t.atoms.icon.color}} />
          <Text style={{color: t.atoms.text.color}}>
            <Trans>Create Circle</Trans>
          </Text>
        </View>
        <Chevron size="sm" style={[t.atoms.icon]} />
      </Button>
      {/* <Button
        color="secondary"
        variant="outline"
        size="medium"
        label="Circle Request"
        onPress={() => navigation.navigate('CircleRequestList')}>
        <View style={[a.flex_1, a.flex_row, a.gap_md]}>
          <MessageNotif style={[t.atoms.icon]} />
          <Text style={{color: t.atoms.text.color}}>
            <Trans>Circle Request</Trans>
          </Text>
          {requestCount > 0 && <Text>{requestCount}</Text>}
        </View>
        <Chevron size="sm" style={[t.atoms.icon]} />
      </Button> */}
      {isLoading && <Loader />}
      {!isLoading && (
        <CircleList
          data={filter}
          onEdit={handlerEdit}
          onInvite={handlerInvite}
        />
      )}
      {!filter?.length && (
        <EmptyBox icon="heart" message="There are no circle yet." />
      )}
    </View>
  )
}
