import {Insets, Platform} from 'react-native'
import {AppBskyActorDefs} from '@atproto/api'

export const isDev = process.env.EXPO_PUBLIC_ENV === 'development'
export const LOCAL_DEV_SERVICE =
  Platform.OS === 'android' ? 'http://10.0.2.2:2583' : 'http://localhost:2583'
export const STAGING_SERVICE = 'https://staging.bsky.dev'
export const BSKY_SERVICE = !isDev
  ? 'https://pds.insider3.io'
  : 'https://pds.insider.social'

export const TW_CLIENT_ID = !isDev
  ? 'VVFJcTh5Y29yMThLUXgxaU9fMko6MTpjaQ'
  : 'RWczeDZxNWt4RXFHOXluWmVuYTQ6MTpjaQ'

export const TW_TARGET_URL = !isDev
  ? 'https://sipz.io/authorize'
  : 'https://app-test.insider.win/authorize'

export const SHARE_URL = !isDev
  ? 'https://sipz.io/'
  : 'https://app-test.insider.win/'

export const SDN_SERVICE = 'https://beginner.sending.network'
export const PUBLIC_BSKY_SERVICE = isDev
  ? 'https://bsky.insider.social'
  : 'https://bsky.insider3.io'
export const DEFAULT_SERVICE = BSKY_SERVICE
const HELP_DESK_LANG = 'en-us'
export const HELP_DESK_URL = `https://blueskyweb.zendesk.com/hc/${HELP_DESK_LANG}`
export const EMBED_SERVICE = 'https://embed.bsky.app'
export const EMBED_SCRIPT = `${EMBED_SERVICE}/static/embed.js`
export const BSKY_DOWNLOAD_URL = 'https://bsky.app/download'
export const ENTITY_URL = 'https://entity.insider.social'
export const VISITOR_SERVICE = isDev
  ? 'https://feedgen.insider.social'
  : 'https://feedgen.insider3.io'

// HACK
// Yes, this is exactly what it looks like. It's a hard-coded constant
// reflecting the number of new users in the last week. We don't have
// time to add a route to the servers for this so we're just going to hard
// code and update this number with each release until we can get the
// server route done.
// -prf
export const JOINED_THIS_WEEK = 21797 // as of Jul5 2024

const BASE_FEEDBACK_FORM_URL = `${HELP_DESK_URL}/requests/new`
export function FEEDBACK_FORM_URL({
  email,
  handle,
}: {
  email?: string
  handle?: string
}): string {
  let str = BASE_FEEDBACK_FORM_URL
  if (email) {
    str += `?tf_anonymous_requester_email=${encodeURIComponent(email)}`
    if (handle) {
      str += `&tf_17205412673421=${encodeURIComponent(handle)}`
    }
  }
  return str
}

export const MAX_DISPLAY_NAME = 64
export const MAX_DESCRIPTION = 256

export const MAX_GRAPHEME_LENGTH = 5000

export const MAX_DM_GRAPHEME_LENGTH = 1000

// Recommended is 100 per: https://www.w3.org/WAI/GL/WCAG20/tests/test3.html
// but increasing limit per user feedback
export const MAX_ALT_TEXT = 1000

export function IS_TEST_USER(handle?: string) {
  return handle && handle?.endsWith('.test')
}

export function IS_PROD_SERVICE(url?: string) {
  return url && url !== STAGING_SERVICE && !url.startsWith(LOCAL_DEV_SERVICE)
}

export const PROD_DEFAULT_FEED = (rkey: string) =>
  `at://did:plc:z72i7hdynmk6r22z27h6tvur/app.bsky.feed.generator/${rkey}`

export const POST_IMG_MAX = {
  width: 2000,
  height: 2000,
  size: 1000000,
}

export const STAGING_LINK_META_PROXY =
  'https://cardyb.staging.bsky.dev/v1/extract?url='

export const PROD_LINK_META_PROXY = 'https://cardyb.bsky.app/v1/extract?url='

export function LINK_META_PROXY(serviceUrl: string) {
  if (IS_PROD_SERVICE(serviceUrl)) {
    return PROD_LINK_META_PROXY
  }

  return STAGING_LINK_META_PROXY
}

export const STATUS_PAGE_URL = 'https://status.bsky.app/'

// Hitslop constants
export const createHitslop = (size: number): Insets => ({
  top: size,
  left: size,
  bottom: size,
  right: size,
})
export const HITSLOP_10 = createHitslop(10)
export const HITSLOP_20 = createHitslop(20)
export const HITSLOP_30 = createHitslop(30)
export const POST_CTRL_HITSLOP = {top: 5, bottom: 10, left: 10, right: 10}
export const BACK_HITSLOP = HITSLOP_30
export const MAX_POST_LINES = 30

export const BSKY_APP_ACCOUNT_DID = 'did:plc:z72i7hdynmk6r22z27h6tvur'

export const BSKY_FEED_OWNER_DIDS = [
  BSKY_APP_ACCOUNT_DID,
  'did:plc:vpkhqolt662uhesyj6nxm7ys',
  'did:plc:q6gjnaw2blty4crticxkmujt',
]

export const SQUARE_FEED_URI = !isDev
  ? 'at://did:plc:j3qdpjlk2lasdwqibu34r6va/app.bsky.feed.generator/square'
  : 'at://did:plc:bffohczymktxcd76fcow5ngd/app.bsky.feed.generator/square'

export const FOLLOW_FEED_URI = !isDev
  ? 'at://did:plc:j3qdpjlk2lasdwqibu34r6va/app.bsky.feed.generator/following'
  : 'at://did:plc:bffohczymktxcd76fcow5ngd/app.bsky.feed.generator/following'

export const FEED_CIRCLE_URI = !isDev
  ? 'at://did:plc:j3qdpjlk2lasdwqibu34r6va/app.bsky.feed.generator/circle'
  : 'at://did:plc:bffohczymktxcd76fcow5ngd/app.bsky.feed.generator/circle'

export const DISCOVER_SAVED_FEED = {
  type: 'feed',
  value: SQUARE_FEED_URI,
  pinned: true,
}
export const TIMELINE_SAVED_FEED = {
  type: 'timeline',
  value: 'following',
  pinned: true,
}

export const RECOMMENDED_SAVED_FEEDS: Pick<
  AppBskyActorDefs.SavedFeed,
  'type' | 'value' | 'pinned'
>[] = [DISCOVER_SAVED_FEED, TIMELINE_SAVED_FEED]

export const KNOWN_SHUTDOWN_FEEDS = [
  'at://did:plc:wqowuobffl66jv3kpsvo7ak4/app.bsky.feed.generator/the-algorithm', // for you by skygaze
]

export const GIF_SERVICE = 'https://gifs.bsky.app'

export const GIF_SEARCH = (params: string) =>
  `${GIF_SERVICE}/tenor/v2/search?${params}`
export const GIF_FEATURED = (params: string) =>
  `${GIF_SERVICE}/tenor/v2/featured?${params}`

export const MAX_LABELERS = 20

// web3
export const PUBLIC_ENV_CHAINID = isDev ? 84532 : 8453
export const WALLETCONNECT_PROJECT_ID = '5d110d44aeb678c9fc74b40c98b8e106'
export const INFURA_KEY = '155a6c98336a4fcdb9a30f55f22e48ce'
export const SUPPORT_CHAINS = [
  {
    chainId: 8453,
    name: 'Base',
    explorerUrl: 'https://basescan.org',
    rpcUrl: 'https://mainnet.base.org',
    currency: 'ETH',
    native_currency: {symbol: 'ETH', name: 'ETH', decimals: 18},
    contract: {
      STAKE_ADDRESS: '0x43A43FF2856900fa53AdbeCEf5A1ad61D4835751',
      DONATE_ADDRESS: '0xBa58c2559a4F346fE00F55B83FF0cdbf6A934FB9',
      BET_ADDRESS: '',
      REQUEST_BET_ADDRESS: '',
    },
    payTokenAddress: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
  },
  {
    chainId: 84532,
    name: 'Base-sopolia',
    explorerUrl: 'https://sepolia.basescan.org',
    rpcUrl: 'https://sepolia.base.org',
    currency: 'ETH',
    native_currency: {symbol: 'ETH', name: 'ETH', decimals: 18},
    contract: {
      STAKE_ADDRESS: '0x4d5CD2dDD84838d115161B1947f57DC2D147efec',
      DONATE_ADDRESS: '0xC191313AA2B5AD8CdF5D18b48f17Ef117cCef4e8',
      BET_ADDRESS: '0xaaD6d1728561d592572C751800248F59f110ab2c',
      REQUEST_BET_ADDRESS: '0x4720c6A44673eA2e726aD46f02817078806Df170',
    },
    payTokenAddress: '0xDCfd9AefbD55556bDB74d42D08a0FdAe0DA5ad23',
  },
]
export const RPC_MAP = {
  8453: 'https://base.drpc.org',
  84532: 'https://sepolia.base.org',
}

export const WALLETCONNECT_PROVIDER_METADATA = {
  name: 'Sipz',
  description: 'Sipz',
  url: 'https://sipz.io',
  icons: ['https://sipz.io'],
  redirect: {
    native: '',
  },
}

export const WALLETCONNECT_CHAINS = [
  {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://mainnet.infura.io/v3/c3fbac6d01a74109b3c5088d657f528e',
  },
  {
    chainId: 8453,
    name: 'Base',
    currency: 'ETH',
    explorerUrl: 'https://basescan.org',
    rpcUrl: 'https://base.drpc.org',
  },
  {
    chainId: 84532,
    name: 'Base-sopolia',
    currency: 'ETH',
    explorerUrl: 'https://sepolia.basescan.org',
    rpcUrl: 'https://sepolia.base.org',
  },
]

export const WALLETCONNECT_SESSION_PARAMS = {
  namespaces: {
    eip155: {
      methods: [
        'personal_sign',
        'eth_sendTransaction',
        'eth_accounts',
        'eth_requestAccounts',
        'eth_call',
        'eth_getBalance',
        'eth_sendRawTransaction',
        'eth_sign',
        'eth_signTransaction',
        'eth_signTypedData',
        'eth_signTypedData_v3',
        'eth_signTypedData_v4',
        'wallet_switchEthereumChain',
        'wallet_addEthereumChain',
        'wallet_getPermissions',
        'wallet_requestPermissions',
        'wallet_registerOnboarding',
        'wallet_watchAsset',
        'wallet_scanQRCode',
      ],
      defaultChain: 'eip155:1',
      chains: ['eip155:8453', 'eip155:84532'],
      events: [
        'accountsChanged',
        'chainChanged',
        'message',
        'disconnect',
        'connect',
        'session_update',
        'session_delete',
      ],
      rpcMap: {
        8453: 'https://base.drpc.org',
        // https://base.drpc.org
        // https://base-rpc.publicnode.com
        // https://base.meowrpc.com
        84532: 'https://sepolia.base.org',
      },
    },
  },
}
