import React from 'react'
import {Text, View, ViewStyle} from 'react-native'
import {Trans} from '@lingui/macro'

import {CloseIcon} from '#/view/icons/ModalIcons'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import * as Toggle from '#/components/forms/Toggle'
import {IconCircle} from '#/components/IconCircle'
import {PencilLine_Stroke2_Corner0_Rounded as Pencil} from '#/components/icons/Pencil'

export type Tag = {
  id: number
  name: string
  selected?: boolean
}

type Props = {
  selected?: boolean
  tag: Tag
  showEditor?: boolean
  showCheckbox?: boolean
  onSelected?: (selected: boolean, tag: Tag) => void
}
export default function TagItem({
  selected,
  tag,
  showCheckbox,
  showEditor,
  onSelected,
}: Props) {
  const t = useTheme()
  const values = selected ? [tag.name] : []

  const handleSelected = (value: Tag) => {
    onSelected?.(!selected, value)
  }

  return (
    <Button
      style={[
        a.border,
        // a.flex_row,
        // a.justify_start,
        a.align_center,
        // a.rounded_full,
        {width: 106, minHeight: 36, borderRadius: 8},
        selected
          ? {
              backgroundColor: t.palette.yellow_30,
              borderColor: t.palette.primary_active,
            }
          : {...t.atoms.bg_btn_default, borderColor: 'transparent'},
      ]}
      variant="outline"
      size="xsmall"
      label="follower"
      onPress={() => handleSelected?.(tag)}>
      <View
        style={[
          a.flex_1,
          a.flex_row,
          a.align_center,
          a.justify_center,
          a.gap_md,
        ]}>
        {showEditor && (
          <IconCircle
            style={{
              width: 24,
              height: 24,
              backgroundColor: t.palette.contrast_100,
            }}
            icon={Pencil}
            size="sm"
            iconStyle={[{color: t.palette.contrast_700}]}
          />
        )}
        <Text
          style={[
            t.atoms.text_sub,
            a.text_xs,
            selected
              ? [a.font_semibold, {color: t.palette.black_50}]
              : a.font_normal,
          ]}>
          <Trans>{tag.name}</Trans>
        </Text>
      </View>
      {showCheckbox && (
        <Toggle.Group
          type="checkbox"
          label="Tag"
          values={values}
          onChange={() => handleSelected(tag)}>
          <Toggle.Item name={`${tag.name}`} label="Switch Mask">
            <Toggle.Checkbox />
          </Toggle.Item>
        </Toggle.Group>
      )}
    </Button>
  )
}

type SimpleTagProps = {
  tag: Tag
  isFilter?: boolean
  style?: ViewStyle
  showClose?: boolean
  onDelete?: (value: Tag) => void
}
export const SimpleTag = ({
  tag,
  isFilter,
  style,
  showClose,
  onDelete,
}: SimpleTagProps) => {
  const t = useTheme()
  return (
    <Button
      style={[a.justify_start, style]}
      key={tag.name}
      label="tag"
      onPress={() => onDelete?.(tag)}>
      <View
        key={tag.name}
        style={[
          a.rounded_xs,
          a.p_sm,
          a.justify_center,
          a.align_center,
          a.flex_row,
          a.gap_sm,
          {
            backgroundColor: isFilter
              ? '#8D8D92'
              : t.atoms.bg_btn_default.backgroundColor,
          },
          style,
        ]}>
        <Text
          style={[
            {fontSize: 12},
            {color: isFilter ? '#ffffff' : t.atoms.text_sub.color},
          ]}>
          {tag.name}
        </Text>
        {showClose && <CloseIcon circleColor="rgba(0,0,0,0.3)" size={18} />}
      </View>
    </Button>
  )
}
