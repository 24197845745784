import React, {ComponentProps} from 'react'
import {StyleSheet, TouchableOpacity, View} from 'react-native'

// import {Trans} from '@lingui/macro'
import {usePalette} from '#/lib/hooks/usePalette'
import {colors, s} from '#/lib/styles'
// import {useModalControls} from '#/state/modals'
import {
  CollectIcon,
  ListIcon,
  MessageIcon,
  NoticeIcon,
  ProfileIcon,
  SettingIcon,
} from '#/view/icons/DrawerIcons'
import {InviteIcon} from '#/view/icons/TopBarIcons'
import {Text} from 'view/com/util/text/Text'
import {useTheme} from '#/alf'
// import {Button} from '#/components/Button'
import {Circle_Left_Nav as Circle} from '#/components/icons/Circle'
import {HomeOpen_Stoke2_Corner0_Rounded as HomeIcon} from '#/components/icons/HomeOpen'
import {Mask} from '#/components/icons/Mask'

interface MenuItemProps extends ComponentProps<typeof TouchableOpacity> {
  icon: JSX.Element
  label: string
  link?: JSX.Element
  count?: string
  bold?: boolean
}
interface IComItem {
  isActive: boolean
  onPress: () => void
}

export function MenuItem({
  icon,
  label,
  accessibilityLabel,
  count,
  bold,
  onPress,
}: MenuItemProps) {
  const pal = usePalette('default')
  return (
    <TouchableOpacity
      testID={`menuItemButton-${label}`}
      style={styles.menuItem}
      onPress={onPress}
      accessibilityRole="tab"
      accessibilityLabel={accessibilityLabel}
      accessibilityHint="">
      <View style={[styles.menuItemIconWrapper]}>
        {icon}
        {count ? (
          <View
            style={[
              styles.menuItemCount,
              count.length > 2
                ? styles.menuItemCountHundreds
                : count.length > 1
                ? styles.menuItemCountTens
                : undefined,
            ]}>
            <Text style={styles.menuItemCountLabel} numberOfLines={1}>
              {count}
            </Text>
          </View>
        ) : undefined}
      </View>
      <Text
        type={bold ? '2xl-bold' : '2xl'}
        style={[pal.text, s.flex1]}
        numberOfLines={1}>
        {label}
      </Text>
    </TouchableOpacity>
  )
}

export const HomeMenuItem = React.memo(
  ({onPress}: IComItem): React.ReactNode => {
    const pal = usePalette('default')
    return (
      <MenuItem
        icon={<HomeIcon style={pal.text} />}
        label="Home"
        bold={true}
        onPress={onPress}
      />
    )
  },
)
HomeMenuItem.displayName = 'HomeMenuItem'

export const ProfileMenuItem = React.memo(
  ({onPress}: IComItem): React.ReactNode => {
    return (
      <MenuItem
        icon={<ProfileIcon />}
        label="Profile"
        bold={true}
        onPress={onPress}
      />
    )
  },
)
ProfileMenuItem.displayName = 'ProfileMenuItem'

export const BookmarksMenuItem = React.memo(
  ({onPress}: IComItem): React.ReactNode => {
    return (
      <MenuItem
        icon={<CollectIcon />}
        label="Bookmarks"
        bold={true}
        onPress={onPress}
      />
    )
  },
)
BookmarksMenuItem.displayName = 'BookmarksMenuItem'

export const MaskMenuItem = React.memo(
  ({onPress}: IComItem): React.ReactNode => {
    const t = useTheme()
    return (
      <MenuItem
        icon={<Mask style={[t.atoms.text]} />}
        label="Mask"
        bold={true}
        onPress={onPress}
      />
    )
  },
)
MaskMenuItem.displayName = 'MaskMenuItem'

export const CircleMenuItem = React.memo(
  ({onPress, isActive}: IComItem): React.ReactNode => {
    const t = useTheme()
    // const {bind} = useTelegramLink()
    // const {openModal} = useModalControls()

    // const link = !bind ? (
    //   <Button
    //     label="Link Telegram"
    //     onPress={() => {
    //       openModal({
    //         name: 'telegram',
    //       })
    //     }}>
    //     <Text>
    //       <Trans>Link Telegram</Trans>
    //     </Text>
    //   </Button>
    // ) : undefined
    return (
      <MenuItem
        icon={<Circle style={[t.atoms.text]} />}
        disabled={isActive}
        label="Connections"
        // link={link}
        bold={true}
        onPress={onPress}
      />
    )
  },
)
CircleMenuItem.displayName = 'CircleMenuItem'

export const AssetsMenuItem = React.memo(
  ({onPress}: IComItem): React.ReactNode => {
    return (
      <MenuItem
        icon={<ListIcon />}
        label="Assets"
        bold={true}
        onPress={onPress}
      />
    )
  },
)
AssetsMenuItem.displayName = 'AssetsMenuItem'

export const MessageMenuItem = React.memo(
  ({onPress}: IComItem): React.ReactNode => {
    return (
      <MenuItem
        icon={<MessageIcon />}
        label="Messages"
        bold={true}
        onPress={onPress}
      />
    )
  },
)
MessageMenuItem.displayName = 'MessageMenuItem'

export const NotificationsMenuItem = React.memo(
  ({onPress}: IComItem): React.ReactNode => {
    return (
      <MenuItem
        icon={<NoticeIcon />}
        label="Notifications"
        bold={true}
        onPress={onPress}
      />
    )
  },
)
NotificationsMenuItem.displayName = 'NotificationsMenuItem'

export const SettingsMenuItem = React.memo(
  ({onPress}: IComItem): React.ReactNode => {
    return (
      <MenuItem
        icon={<SettingIcon />}
        label="Settings"
        bold={true}
        onPress={onPress}
      />
    )
  },
)
SettingsMenuItem.displayName = 'SettingsMenuItem'

export const InviteMenuItem = React.memo(
  ({onPress}: IComItem): React.ReactNode => {
    return (
      <MenuItem
        icon={<InviteIcon />}
        label="Invite"
        bold={true}
        onPress={onPress}
      />
    )
  },
)
InviteMenuItem.displayName = 'InviteMenuItem'

const styles = StyleSheet.create({
  menuItem: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 16,
  },
  menuItemIconWrapper: {
    width: 24,
    height: 24,
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 12,
  },
  menuItemCount: {
    position: 'absolute',
    width: 'auto',
    right: -6,
    top: -4,
    backgroundColor: colors.blue3,
    paddingHorizontal: 4,
    paddingBottom: 1,
    borderRadius: 6,
  },
  menuItemCountTens: {
    width: 25,
  },
  menuItemCountHundreds: {
    right: -12,
    width: 34,
  },
  menuItemCountLabel: {
    fontSize: 12,
    fontWeight: 'bold',
    fontVariant: ['tabular-nums'],
    color: colors.white,
  },
})
