import React, {useCallback} from 'react'
import {ImageBackground, StyleSheet, Text, View} from 'react-native'
import {Trans} from '@lingui/macro'
import {useNavigation} from '@react-navigation/native'

import {SHARE_URL} from '#/lib/constants'
import {NavigationProp} from '#/lib/routes/types'
import {capitalize} from '#/lib/strings/capitalize'
import {isNative} from '#/platform/detection'
import {shareUrl} from 'lib/sharing'
import {ViewHeader} from '#/view/com/util/ViewHeader'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonText} from '#/components/Button'
import * as Dialog from '#/components/Dialog'
import {IconCircle} from '#/components/IconCircle'
// import { ArrowOutOfBox_Stroke2_Corner0_Rounded as ShareIcon } from '#/components/icons/ArrowOutOfBox'
import {ArrowOutOfBoxRound_Stroke2_Corner0_Rounded as Share} from '#/components/icons/ArrowOutOfBox'
import {ChevronRight_Stroke2_Corner0_Rounded as Chevron} from '#/components/icons/Chevron'
// import {Edit_Stroke2_Corner0_Rounded as Edit} from '#/components/icons/Edit'
import {Group2_Stroke2_Corner0_Rounded as InviteIcon} from '#/components/icons/Group'
import {GroupFill_Stroke2_Corner0_Rounded as GroupFill} from '#/components/icons/GroupFill'
import {Link_Stroke2_Corner0_Rounded as Link} from '#/components/icons/Link'
import {PersonPlus_Filled_Stroke2_Corner0_Rounded as PersonPlus} from '#/components/icons/Person'
import {PlusSmall_Stroke2_Corner0_Rounded as Plus} from '#/components/icons/Plus'
import {SettingsGear2_Stroke2_Corner0_Rounded as SettingsIcon} from '#/components/icons/SettingsGear2'
import {H2} from '#/components/Typography'
import {CircleAvatar} from './CircleAvatar'
import {useCircleItem} from './hooks'

type Props = {
  circleId: string
}

const inviteIcon = (color: string) => <InviteIcon fill={color} />
const linkIcon = (color: string) => <Link fill={color} />
// const editIcon = (color: string) => <Edit fill={color} />
const shareIcon = (color: string) => <Share fill={color} />

type InviteButtonType = {
  name: string
  icon: (color: string) => JSX.Element
  callback: () => void
}

export default function CircleHeader({circleId}: Props) {
  const t = useTheme()
  const navigation = useNavigation<NavigationProp>()
  const basic = Dialog.useDialogControl()
  const {data, isLoading} = useCircleItem(+circleId)

  const isOwner = data?.isOwner

  const applyOrInvite = async (type: ActionType) => {
    if (type === 'join') {
      navigation.navigate('CircleApply', {id: circleId})
    } else if (type === 'invite') {
      basic.open()
    }
  }

  const renderButton = () => {
    return (
      <View style={[a.flex_1, a.flex_row, a.justify_end, a.gap_md]}>
        {/* <Button label="">
          <IconCircle
            style={[
              { width: 32, height: 32, backgroundColor: t.palette.black_30 },
            ]}
            icon={LampOn}
            iconStyle={[{ color: t.atoms.bg_white.backgroundColor }]}
            size="sm"
          />
        </Button> */}
        {isOwner && (
          <Button
            label=""
            onPress={() => navigation.navigate('CircleUpdate', {id: circleId})}>
            <IconCircle
              style={[
                {width: 32, height: 32, backgroundColor: t.palette.black_30},
              ]}
              icon={SettingsIcon}
              iconStyle={[{color: t.atoms.bg_white.backgroundColor}]}
              size="sm"
            />
          </Button>
        )}
        {/* <Button label="">
          <IconCircle
            style={[
              { width: 32, height: 32, backgroundColor: t.palette.black_30 },
            ]}
            icon={ShareIcon}
            iconStyle={[{ color: t.atoms.bg_white.backgroundColor }]}
            size="sm"
          />
        </Button> */}
      </View>
    )
  }

  const inviteButtons = [
    {
      name: 'Invite via Friends',
      icon: inviteIcon,
      callback: () => navigation.navigate('CircleInvite', {id: circleId}),
    },
    {
      name: 'Copy Link',
      icon: linkIcon,
      callback: () => shareUrl(`${SHARE_URL}circle/${circleId}`),
    },
    // {
    //   name: 'Share via tea',
    //   icon: editIcon,
    //   callback: () => navigation.navigate('CircleUpdate', {id: circleId}),
    // },
    isNative && {
      name: 'Share via...',
      icon: shareIcon,
      callback: () => {
        // TODO
      },
    },
  ].filter(Boolean) as InviteButtonType[]

  const onPressMembers = useCallback(() => {
    navigation.navigate('CircleMembers', {id: circleId})
  }, [circleId, navigation])

  return (
    <ImageBackground
      source={require('../../../../assets/imgs/circle_header_bg.png')}
      style={styles.circleHeader}>
      <View
        style={{
          position: 'absolute',
          height: '100%',
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        }}
      />
      <ViewHeader
        canGoBack
        alwaysWhite
        title={''}
        renderButton={renderButton}
        style={[a.bg_transparent]}
        showOnDesktop
        showBorder={false}
      />
      <View
        style={[
          a.flex_row,
          a.align_center,
          a.gap_lg,
          a.px_xl,
          a.mb_lg,
          a.pt_sm,
        ]}>
        <CircleAvatar circle={data} size={64} showFlag={false} />
        <View style={[a.gap_sm]}>
          <H2 style={[a.font_bold, {fontSize: 18, color: 'white'}]}>
            {data?.name}
          </H2>
          <Button label="" style={[a.gap_md]} onPress={onPressMembers}>
            <GroupFill fill={t.atoms.bg_white.backgroundColor} />
            <Text style={styles.text}>
              <Trans>{data?.memberCount} Members</Trans>
            </Text>
            <IconCircle
              style={[
                {width: 14, height: 14, backgroundColor: t.palette.black_30},
              ]}
              icon={Chevron}
              iconStyle={[t.atoms.text, {color: 'white', width: 8, height: 8}]}
              size="sm"
            />
          </Button>
        </View>
        {!isLoading && isOwner && (
          <ActionButton
            actionType={isOwner ? 'invite' : 'join'}
            onPress={applyOrInvite}
          />
        )}
      </View>
      <View style={[a.px_xl, {height: 42, overflow: 'hidden'}]}>
        <Text style={[{lineHeight: 21}, styles.text]}>
          <Trans>{data?.bio}</Trans>
        </Text>
      </View>

      <Dialog.Outer control={basic}>
        <Dialog.Inner label="test" style={{minHeight: 360}}>
          <View
            style={[
              a.flex_1,
              a.align_center,
              a.justify_between,
              a.pb_3xl,
              a.gap_lg,
            ]}>
            <View
              style={[
                a.w_full,
                a.align_center,
                a.justify_between,
                a.pb_3xl,
                a.gap_lg,
              ]}>
              {inviteButtons.map(item => (
                <Button
                  label={item.name}
                  key={item.name}
                  shape="square"
                  onPress={() => item.callback?.()}
                  style={[
                    a.w_full,
                    a.py_lg,
                    a.px_xl,
                    a.border,
                    a.gap_md,
                    a.justify_start,
                    {borderBlockColor: t.atoms.text_contrast_low.color},
                  ]}>
                  <ButtonText>{item.icon(t.atoms.text.color)}</ButtonText>
                  <ButtonText style={[t.atoms.text]}>{item.name}</ButtonText>
                </Button>
              ))}
            </View>
            <Button
              style={[{marginTop: 'auto'}, a.w_full]}
              color="secondary"
              onPress={() => basic.close()}
              label="Cancel"
              variant="solid"
              size="large">
              <ButtonText style={[a.font_bold, a.text_md]}>
                <Trans>Cancel</Trans>
              </ButtonText>
            </Button>
          </View>
        </Dialog.Inner>
      </Dialog.Outer>
    </ImageBackground>
  )
}

type ActionType = 'join' | 'invite'
type ActionButtomProps = {
  actionType: ActionType
  onPress: (type: ActionType) => Promise<void>
}

function ActionButton({actionType, onPress}: ActionButtomProps) {
  const t = useTheme()

  return (
    <Button
      label=""
      style={[
        a.align_center,
        a.justify_center,
        a.gap_sm,
        a.p_sm,
        a.rounded_sm,
        {
          marginLeft: 'auto',
        },
        t.atoms.bg_black_30,
      ]}
      onPress={() => onPress(actionType)}>
      {/* <IconCircle
        style={[{width: 14, height: 14}]}
        icon={actionType === 'join' ? Plus : PersonPlus}
        // iconStyle={[{color: t.atoms.bg_white.backgroundColor}]}
        size="xs"
      /> */}
      {actionType === 'join' ? (
        <Plus style={{color: 'white'}} />
      ) : (
        <PersonPlus style={{color: 'white'}} />
      )}
      <ButtonText>
        <Trans>{capitalize(actionType)}</Trans>
      </ButtonText>
    </Button>
  )
}

const styles = StyleSheet.create({
  circleHeader: {
    paddingBottom: 16,
    backgroundColor: '#4A87F8',
  },
  topBanner: {
    flex: 1,
  },
  text: {
    color: 'white',
  },
  absolute: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
})
