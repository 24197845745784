import React from 'react'
import {
  ActivityIndicator,
  FlatList as RNFlatList,
  Pressable,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'

import {fuzzyMatch} from '#/lib/hooks/Tools'
import {NavigationProp} from '#/lib/routes/types'
import {useModalControls} from '#/state/modals'
import {CloseIcon} from '#/view/icons/ModalIcons'
import {CircleItemProps} from '#/view/screens/Circle'
import {CircleAvatar} from '#/view/screens/Circle/CircleAvatar'
import {useCircleList} from '#/view/screens/Circle/hooks'
import {atoms as a, useTheme} from '#/alf'
import {CheckedIcon, UncheckedIcon} from '#/components/icons/StakeIcons'
import {EmptyBox} from '../../util/EmptyBox'
import {Button} from '../../util/forms/Button'
import {SearchInput} from '../../util/forms/SearchInput'
import {CircleListType, VisibleRangeType} from './PostSelectCircle'

export function SelectCircleList({
  visibleRange,
  list,
  setList,
}: {
  list: CircleListType[]
  setList: React.Dispatch<React.SetStateAction<CircleListType[]>>
  visibleRange: VisibleRangeType
}) {
  const t = useTheme()
  const {_} = useLingui()
  const {data: circles, isLoading} = useCircleList()
  const {closeModal} = useModalControls()
  const navigation = useNavigation<NavigationProp>()

  const [query, setQuery] = React.useState('')
  // const [list, setList] = React.useState<CircleListType[]>([])

  const onChangeQuery = (v: string) => {
    setQuery(v)
  }
  const onPressCancelSearch = () => {
    setQuery('')
  }

  const toggleSelect = React.useCallback(
    (id: string) => {
      setList(prevItems =>
        prevItems.map(item =>
          item.id === id ? {...item, isSelected: !item.isSelected} : item,
        ),
      )
    },
    [setList],
  )

  React.useEffect(() => {
    setList(
      (circles ?? []).map((o: CircleItemProps) => {
        return {
          ...o,
          isSelected: visibleRange?.visibleCircles?.some(
            item => item.id === o.id,
          ),
        }
      }),
    )
  }, [circles, visibleRange.visibleCircles, setList])

  const renderItem = React.useCallback(
    ({item}: {item: CircleListType}) => {
      return (
        <View
          style={[
            a.flex_row,
            a.align_center,
            a.mb_md,
            a.mr_md,
            a.justify_between,
          ]}
          key={item?.id}>
          <View style={[a.flex_row, a.align_center, a.gap_sm]}>
            <CircleAvatar size={56} circle={item} />
            <View style={[a.gap_xs]}>
              <Text style={[a.text_md, a.font_bold, t.atoms.text]}>
                {item?.name}
              </Text>
              <View>
                {/* <StackAvatar
                  data={item?.members?.map(m => m?.avatar ?? '')}
                  maxCount={4}
                /> */}
                <Text style={[a.text_xs, {color: t.palette.gray_10}]}>
                  {item?.memberCount} Members
                </Text>
              </View>
            </View>
          </View>
          <Pressable
            accessibilityRole="button"
            onPress={() => toggleSelect(item.id)}>
            {item?.isSelected ? (
              <CheckedIcon />
            ) : (
              <UncheckedIcon primaryColor={t.atoms.text.color} />
            )}
          </Pressable>
        </View>
      )
    },
    [t.palette.gray_10, t.atoms.text, toggleSelect],
  )

  return (
    <View style={[a.flex_1]}>
      <View style={[a.mb_md, {height: 42}]}>
        <SearchInput
          query={query}
          onSubmitQuery={() => {}}
          onChangeQuery={onChangeQuery}
          onPressCancelSearch={onPressCancelSearch}
        />
      </View>
      <View style={[a.flex_row, a.flex_wrap]}>
        {list
          .filter(item => item.isSelected)
          ?.map(item => {
            return (
              <CircleCapsule
                data={item}
                key={item?.id}
                onDelete={() => toggleSelect(item.id)}
              />
            )
          })}
      </View>
      <View style={[a.flex_1, a.gap_md, a.mb_sm, a.mt_md, {minHeight: 100}]}>
        {isLoading ? (
          <ActivityIndicator color={t.palette.primary} />
        ) : list && list?.length > 0 ? (
          <RNFlatList
            testID="postSelectCirclesFlatlist"
            data={list?.filter(o => {
              if (query && o?.name) {
                return fuzzyMatch(o?.name, query) ? o : null
              } else {
                return o
              }
            })}
            renderItem={renderItem}
            removeClippedSubviews={true}
            // @ts-ignore our .web version only -prf
            desktopFixedHeight
          />
        ) : (
          <View style={[a.flex_col, a.justify_center, a.align_center]}>
            <EmptyBox
              icon="heart"
              message="There are no circle yet. you can create it."
              viewStyles={{padding: 0}}
            />
            <Button
              testID="goCreateCircleBtn"
              type="default"
              onPress={() => {
                closeModal()
                navigation.navigate('CircleCreate')
              }}
              accessibilityLabel={_(msg`Create`)}
              accessibilityHint=""
              label={_(msg`Create`)}
              labelContainerStyle={{justifyContent: 'center', padding: 4}}
              labelStyle={[a.text_md]}
              style={[
                a.mt_lg,
                a.mb_sm,
                {backgroundColor: t.palette.primary, width: 135},
              ]}
            />
          </View>
        )}
      </View>
    </View>
  )
}

const CircleCapsule = ({
  data,
  onDelete,
}: {
  data: CircleItemProps
  onDelete: any
}) => {
  const t = useTheme()
  return (
    <View
      key={data?.id}
      style={{
        flexDirection: 'row',
        paddingVertical: 2,
        paddingLeft: 4,
        paddingRight: onDelete ? 5 : 10,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: t.atoms.bg_capsule.backgroundColor, //config.background,
        borderRadius: 18,
        marginRight: 10,
        marginBottom: 5,
        maxWidth: '100%',
      }}>
      {/* <UserAvatar
        avatar={avatar}
        type={avatar ? 'user' : (type as 'organization')}
        // size={config.avatarSize}
        size={20}
      /> */}
      <CircleAvatar size={20} circle={data} />
      <View>
        <Text
          style={{
            color: t.atoms.text_capsule.color, //'#5E6272',
            fontSize: 12,
            marginLeft: 6,
          }}>
          {data?.name}
        </Text>
      </View>
      {onDelete && (
        <TouchableOpacity
          accessibilityRole="button"
          style={{
            marginLeft: 5,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          onPress={onDelete}>
          <CloseIcon circleColor={'#000'} />
        </TouchableOpacity>
      )}
    </View>
  )
}
