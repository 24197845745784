import React from 'react'
import {Pressable, Text, View, ViewStyle} from 'react-native'
import {Trans} from '@lingui/macro'
import {useQueryClient} from '@tanstack/react-query'

import {useModalControls} from '#/state/modals'
import {TelegramBindStep} from '#/view/com/modals/TelegramModal/config'
import TagFilter from '#/view/com/tags/TagFilter'
import {Tag} from '#/view/com/tags/TagItem'
import {EmptyBox} from '#/view/com/util/EmptyBox'
import {SearchInput} from '#/view/com/util/forms/SearchInput'
import {atoms as a, useTheme} from '#/alf'
import {Loader} from '#/components/Loader'
import {SmallContact} from '../Telegram/ContactItem'
import ContactList from '../Telegram/ContactList'
import {ContactItemProps} from '.'
import {defaultTags} from './FriendPanel'
import {
  removeDuplicatesWithSet,
  telegramContactList,
  useTelegramContactList,
} from './hooks'

type Props = {
  circleId: number
  selected?: ContactItemProps[]
  historyMembers?: ContactItemProps[]
  onSelected?: (selected: ContactItemProps[]) => void
  style?: ViewStyle
}
export function SelectFriend({
  selected = [],
  historyMembers = [],
  onSelected,
  style,
}: Props) {
  const t = useTheme()
  const queryClient = useQueryClient()
  const {openModal} = useModalControls()

  const [query, setQuery] = React.useState('')
  // const items = ['Telegram Contact']
  // const [tabIndex, setTabIndex] = React.useState(0)
  const [filterTags, setFilterTags] = React.useState<Tag[]>([])
  const {data, isFetching} = useTelegramContactList({
    circleId: -1,
    query,
    tags: filterTags,
  })

  const contacts = React.useMemo(() => {
    if (data) {
      return removeDuplicatesWithSet(data, 'id').filter(
        member => !historyMembers?.find(_member => _member.id === member.id),
      )
    }
    return []
  }, [data, historyMembers])

  const onSubmitQuery = () => {}
  const onPressCancelSearch = () => {
    setQuery('')
  }

  const handleSelected = (isSelected: boolean, _item: ContactItemProps) => {
    let list: ContactItemProps[] = selected
    if (!isSelected) {
      list = selected.filter(item => item.id !== _item.id)
    } else {
      list = [...selected, _item]
    }
    onSelected?.(list)
  }

  const onConfirmFilterTags = (_tags: Tag[]) => {
    setFilterTags(_tags)
    queryClient.invalidateQueries({
      queryKey: [telegramContactList, {tags: _tags}],
    })
  }

  let content = <EmptyBox icon="heart" message="has no contacts yet." />
  if (isFetching) {
    content = (
      <View style={[a.align_center, a.justify_center, a.p_5xl]}>
        <Loader size="lg" />
      </View>
    )
  } else if (contacts.length) {
    const unbindData = contacts.filter(o => !o?.isBindSipz)
    content = (
      <>
        <Text style={[a.font_bold, a.text_md, a.my_md, t.atoms.text]}>
          <Trans>Already On Sipz</Trans>
        </Text>
        <ContactList
          selected={selected}
          users={contacts.filter(o => o?.isBindSipz)}
          historyMembers={historyMembers}
          onSelected={handleSelected}
          filterTags={filterTags}
        />
        <View
          style={[
            a.flex_row,
            a.flex_1,
            a.justify_between,
            a.align_center,
            a.my_md,
          ]}>
          <Text style={[a.font_bold, a.text_md, t.atoms.text]}>
            <Trans>Invite To Sipz</Trans>
          </Text>
          {unbindData?.length > 0 && (
            <Pressable
              accessibilityRole="button"
              onPress={() => {
                openModal({
                  name: 'telegram',
                  currentStep: TelegramBindStep.SendMessage,
                  userIds: unbindData?.map(item => item.id),
                  onCallBack: () => {
                    queryClient.invalidateQueries({
                      queryKey: [telegramContactList],
                    })
                  },
                })
              }}
              style={[
                a.justify_center,
                a.align_center,
                {
                  width: 64,
                  height: 24,
                  borderRadius: 100,
                  backgroundColor: t.palette.gray_15,
                },
              ]}>
              <View>
                <Text
                  style={[
                    a.text_xs,
                    a.font_semibold,
                    {color: t.palette.white},
                  ]}>
                  <Trans>Invite all</Trans>
                </Text>
              </View>
            </Pressable>
          )}
        </View>
        <ContactList
          users={unbindData}
          showCheckbox={false}
          filterTags={filterTags}
          showDegree={false}
          showInvite={true}
        />
      </>
    )
  }

  return (
    <View style={[a.p_xl, {paddingBottom: 100}, style]}>
      <SearchInput
        query={query}
        onSubmitQuery={onSubmitQuery}
        onChangeQuery={setQuery}
        onPressCancelSearch={onPressCancelSearch}
        style={[a.border, t.atoms.input_bg, t.atoms.input_border]}
      />
      {/* <TabBar
        isBold={false}
        hideBorder={true}
        items={items}
        bgColor={a.bg_transparent.backgroundColor}
        selectedPage={tabIndex}
        onSelect={setTabIndex}
        textStyle={[a.text_md, a.font_normal, t.atoms.text]}
        selectedTextStyle={[a.text_md, a.font_heavy, t.atoms.text]}
      /> */}
      <TagFilter
        defaultDisplayTags={defaultTags}
        onConfirm={onConfirmFilterTags}
      />
      {historyMembers.concat(selected)?.length > 0 && (
        <View
          style={[
            a.flex_row,
            // a.pt_md,
            a.pb_md,
            a.flex_wrap,
            a.gap_sm,
            {marginTop: 5},
          ]}>
          {historyMembers.concat(selected).map((contact: ContactItemProps) => {
            const disabled = !!historyMembers?.find(
              _item => _item.id === contact.id,
            )

            return (
              <SmallContact
                key={contact.id}
                user={contact}
                disabled={disabled}
                onSelected={handleSelected}
              />
            )
          })}
        </View>
      )}
      <View>{content}</View>
    </View>
  )
}
