import React, {memo} from 'react'
import {
  Pressable,
  type StyleProp,
  StyleSheet,
  View,
  type ViewStyle,
} from 'react-native'
import {
  AppBskyFeedDefs,
  AppBskyFeedPost,
  RichText as RichTextAPI,
} from '@atproto/api'
import {plural} from '@lingui/macro'

import {POST_CTRL_HITSLOP} from '#/lib/constants'
import {useHaptics} from '#/lib/haptics'
import {Shadow} from '#/state/cache/types'
import {useFeedFeedbackContext} from '#/state/feed-feedback'
import {
  usePostLikeMutationQueue,
  usePostOpposeMutationQueue,
} from '#/state/queries/post'
import {useRequireAuth, useSession} from '#/state/session'
import {
  ProgressGuideAction,
  useProgressGuideControls,
} from '#/state/shell/progress-guide'
import {HotIcon, ReplyIcon, UpvoteIcon} from '#/view/icons/FeedIcons'
import {atoms as a, useTheme} from '#/alf'
import {useCircle} from '../../posts/CircleProvider'
import {formatCount} from '../numeric/format'
import {Text} from '../text/Text'

let CirclePostCtrls = ({
  big,
  post,
  // record,
  feedContext,
  // richText,
  style,
  onPressReply,
  logContext,
  showRightBt,
  rightButton,
}: {
  big?: boolean
  post: Shadow<AppBskyFeedDefs.PostView>
  record: AppBskyFeedPost.Record
  richText: RichTextAPI
  showRightBt?: boolean
  feedContext?: string | undefined
  style?: StyleProp<ViewStyle>
  onPressReply: () => void
  logContext: 'FeedItem' | 'PostThreadItem' | 'Post'
  rightButton: () => React.ReactNode
}): React.ReactNode => {
  const {currentAccount} = useSession()
  const [queueLike, queueUnlike] = usePostLikeMutationQueue(post, logContext)
  const [queueUnoppose] = usePostOpposeMutationQueue(post, logContext)
  const requireAuth = useRequireAuth()
  const {sendInteraction} = useFeedFeedbackContext()
  const {captureAction} = useProgressGuideControls()
  const playHaptic = useHaptics()

  const isCircle = useCircle()

  const onPressToggleLike = React.useCallback(async () => {
    try {
      if (!post.viewer?.like) {
        playHaptic()
        sendInteraction({
          item: post.uri,
          event: 'app.bsky.feed.defs#interactionLike',
          feedContext,
        })
        captureAction(ProgressGuideAction.Like)
        await queueLike()
        if (post.viewer?.oppose) {
          await queueUnoppose()
        }
      } else {
        await queueUnlike()
      }
    } catch (e: any) {
      if (e?.name !== 'AbortError') {
        throw e
      }
    }
  }, [
    playHaptic,
    post.uri,
    post.viewer?.like,
    queueLike,
    queueUnlike,
    sendInteraction,
    captureAction,
    feedContext,
    post.viewer?.oppose,
    queueUnoppose,
  ])

  const t = useTheme()

  return (
    <View style={{flexDirection: 'row'}}>
      <View
        style={[
          a.flex_row,
          a.justify_between,
          a.align_center,
          style,
          !showRightBt && {flex: 1, justifyContent: 'space-between'},
        ]}>
        <View style={styles.item}>
          <HotIcon isCircle={isCircle} />

          <Text
            testID="likeCount"
            style={[
              styles.text,
              {color: isCircle ? 'white' : '#536471'},
              {color: 'transparent'},
            ]}>
            {formatCount(34853456)}
          </Text>
        </View>

        <View style={styles.box}>
          <Pressable
            testID="likeBtn"
            style={styles.item}
            onPress={() => requireAuth(() => onPressToggleLike())}
            accessibilityLabel={
              post.viewer?.like
                ? plural(post.likeCount || 0, {
                    one: 'Unlike (# like)',
                    other: 'Unlike (# likes)',
                  })
                : plural(post.likeCount || 0, {
                    one: 'Like (# like)',
                    other: 'Like (# likes)',
                  })
            }
            accessibilityHint=""
            hitSlop={POST_CTRL_HITSLOP}>
            <UpvoteIcon
              isCircle={isCircle}
              active={!!post.viewer?.like && !!currentAccount?.did}
            />
            {typeof post.likeCount !== 'undefined' && post.likeCount > 0 ? (
              <Text
                testID="likeCount"
                style={[
                  [
                    big ? a.text_md : {fontSize: 15},
                    a.user_select_none,
                    styles.text,
                    {color: !isCircle ? '#552622' : 'white'},
                    post.viewer?.like && !!currentAccount?.did
                      ? !isCircle
                        ? {color: '#eb4988', fontWeight: 'bold'}
                        : null
                      : null,
                  ],
                ]}>
                {formatCount(post.likeCount)}
              </Text>
            ) : undefined}
          </Pressable>
        </View>

        <View style={styles.box}>
          <Pressable
            testID="replyBtn"
            style={styles.item}
            onPress={() => {
              if (!post.viewer?.replyDisabled) {
                requireAuth(() => onPressReply())
              }
            }}
            accessibilityLabel={plural(post.replyCount || 0, {
              one: 'Reply (# reply)',
              other: 'Reply (# replies)',
            })}
            accessibilityHint=""
            hitSlop={POST_CTRL_HITSLOP}>
            <ReplyIcon isCircle={isCircle} />
            {typeof post.replyCount !== 'undefined' && post.replyCount > 0 ? (
              <Text
                style={[
                  styles.text,
                  big ? a.text_md : {fontSize: 15},
                  a.user_select_none,
                  t.atoms.text,
                  {color: !isCircle ? '#552622' : 'white'},
                ]}>
                {formatCount(post.replyCount)}
              </Text>
            ) : undefined}
          </Pressable>
        </View>

        {isCircle && rightButton()}
      </View>
    </View>
  )
}
CirclePostCtrls = memo(CirclePostCtrls)
export {CirclePostCtrls}
const styles = StyleSheet.create({
  box: {
    width: 75,
    justifyContent: 'flex-start',
  },
  item: {
    flexDirection: 'row',
    marginBottom: 5,
  },
  text: {marginLeft: 5, fontSize: 15},
})
