import React, {useEffect} from 'react'
import {
  ActivityIndicator,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
} from 'react-native'
import {Trans} from '@lingui/macro'

import {CompleteIcon} from '#/view/icons/ModalIcons'
import {useTheme} from '#/alf'
import {usePostStepStore} from '../util/sdlStore/PostStepStore'

export const snapPoints = ['70%']

interface IExist {
  post: boolean
  stake: boolean
  bet: boolean
  [key: string]: boolean
}

interface IStep {
  key: keyof ISkip
  description: string
}

enum SkipState {
  Default,
  Display,
  Skipped,
}

interface ISkip {
  post?: SkipState
  stake: SkipState
  bet: SkipState
  guarantor: SkipState
}

const steps: IStep[] = [
  {
    key: 'post',
    description: 'Spill your tea',
  },
  {
    key: 'guarantor',
    description: 'Invite Witnesses & Guarantors',
  },
  {
    key: 'stake',
    description: 'Stake to Validate',
  },
  {
    key: 'bet',
    description: 'Bet to earn',
  },
]

export function Component() {
  const {stepStore, nextStep, finishAllStep} = usePostStepStore()
  const exist: IExist = {
    post: true,
    stake: !!stepStore?.stakeParams,
    bet: !!stepStore?.betParams,
    guarantor: !!stepStore?.guarantorParams,
  }

  const [skip, setSkip] = React.useState<ISkip>({
    stake: SkipState.Default,
    bet: SkipState.Default,
    guarantor: SkipState.Default,
  })

  const skipItem = (key: keyof ISkip) => {
    setSkip(pre => ({
      ...pre,
      [key]: SkipState.Skipped,
    }))
    nextStep()
  }

  useEffect(() => {
    if (stepStore.step === 'start') return
    switch (stepStore.step) {
      case 'stake':
        setTimeout(() => {
          setSkip(pre => ({
            ...pre,
            stake: SkipState.Display,
          }))
        }, 3000)
        break
      case 'bet':
        setTimeout(() => {
          setSkip(pre => ({
            ...pre,
            bet: SkipState.Display,
          }))
        }, 3000)
        break
      case 'finish':
        finishAllStep()
        break
    }
  }, [stepStore.step, finishAllStep])

  const getPending = (key: keyof ISkip) => {
    switch (key) {
      case 'guarantor':
        return stepStore.guarantor.pending
      case 'stake':
        return stepStore.stake.pending
      case 'bet':
        return stepStore.bet.pending
      default:
        return stepStore.posting
    }
  }

  const getComplete = (key: keyof ISkip) => {
    switch (key) {
      case 'guarantor':
        return stepStore.guarantor.finish
      case 'stake':
        return stepStore.stake.finish
      case 'bet':
        return stepStore.bet.finish
      default:
        return !stepStore.posting
    }
  }

  const getSkipButton = (item: IStep) => {
    if (item.key === 'post') return null
    if (getComplete(item.key)) return null
    const currentSkipState = skip[item.key]
    let showSkip = false,
      showSkipped = false
    if (currentSkipState === SkipState.Display) {
      showSkip = true
    } else if (currentSkipState === SkipState.Skipped) {
      showSkipped = true
    }
    return (
      <>
        {showSkip && (
          <TouchableOpacity
            accessibilityRole="button"
            style={{
              borderRadius: 8,
              backgroundColor: t.palette.primary,
              padding: 5,
              width: 'auto',
              marginLeft: 15,
            }}
            onPress={() => skipItem(item.key)}>
            <Text
              style={[
                {
                  fontSize: 12,
                  fontWeight: '500',
                  color: t.palette.black,
                },
              ]}>
              Skip
            </Text>
          </TouchableOpacity>
        )}

        {showSkipped && (
          <TouchableOpacity
            accessibilityRole="button"
            style={{
              borderRadius: 8,
              backgroundColor: t.palette.primary,
              padding: 5,
              opacity: 0.5,
              width: 'auto',
              marginLeft: 15,
            }}
            onPress={() => {}}>
            <Text
              style={[
                {
                  fontSize: 12,
                  fontWeight: '500',
                },
                t.atoms.text,
              ]}>
              Skipped
            </Text>
          </TouchableOpacity>
        )}
      </>
    )
  }

  let stepCount = 1

  const t = useTheme()

  return (
    <View style={styles.page}>
      <Text
        style={[
          {
            fontSize: 19,
            fontWeight: '600',
            textAlign: 'center',
            marginBottom: 24,
          },
          t.atoms.text,
        ]}>
        Spilling...
      </Text>
      {steps.map((stp, index) => {
        return (
          <>
            {exist[stp.key] && (
              <View
                key={index}
                style={[
                  styles.item,
                  getPending(stp.key) && {borderColor: t.palette.primary},
                ]}>
                <View style={{flex: 1}}>
                  <Text
                    style={[
                      {
                        fontWeight: '600',
                        fontSize: 14,
                        marginBottom: 5,
                      },
                      t.atoms.text,
                    ]}>
                    Step {stepCount++}
                  </Text>
                  <Text style={[styles.description, t.atoms.text]}>
                    {stp.description}
                  </Text>
                </View>

                {getPending(stp.key) && (
                  <ActivityIndicator color={t.palette.primary} />
                )}

                {getSkipButton(stp)}
                {getComplete(stp.key) && <CompleteIcon />}
              </View>
            )}
          </>
        )
      })}
      {skip.stake !== SkipState.Default && (
        <Text style={[styles.p, t.atoms.text]}>
          <Trans>
            If you encounter a stuck issue, you could skip this step, and retry
            it on the post detail page after the issue is solved.
          </Trans>
        </Text>
      )}
      <View style={{alignItems: 'center', marginTop: 20}}>
        <View
          style={{
            flexDirection: 'row',
            width: '80%',
            alignItems: 'center',
            gap: 3,
            justifyContent: 'center',
            padding: 10,
            borderRadius: 100,
            backgroundColor: t.palette.primary,
          }}>
          <ActivityIndicator color={t.palette.black} />
          <Text
            style={{
              fontSize: 16,
              fontWeight: '600',
              color: '#000',
            }}>
            Pending
          </Text>
        </View>
      </View>
    </View>
  )
}
const styles = StyleSheet.create({
  page: {
    width: '100%',
    padding: 10,
  },
  item: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderWidth: 1,
    borderColor: 'rgba(151, 151, 151, 0.2)',
    borderRadius: 6,
    paddingVertical: 11,
    paddingHorizontal: 16,
    marginBottom: 16,
  },
  step: {
    // fontWeight: 600,
    fontSize: 14,
    marginBottom: 5,
  },
  description: {
    fontSize: 14,
  },
  p: {
    marginTop: 8,
    fontSize: 14,
    lineHeight: 18,
  },
})
