import React, {useCallback, useEffect, useState} from 'react'
import {StyleSheet, Text, View} from 'react-native'
import {Trans} from '@lingui/macro'

import {useModalControls} from '#/state/modals'
import {LogoYellow} from '#/view/icons/LogoYellow'
import {ContactItemProps} from '#/view/screens/Circle'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonIcon} from '#/components/Button'
import {Check_Stroke2_Corner0_Rounded as Check} from '#/components/icons/Check'
import {DotGrid_Stroke2_Corner0_Rounded as DotsHorizontal} from '#/components/icons/DotGrid'
import {Telegram} from '#/components/icons/Telegram'
import {Loader} from '#/components/Loader'
import {TelegramBindStep} from './config'
import {useTelegramLink} from './hooks'

interface IProps {
  authSuccess: (contacts?: ContactItemProps[]) => void
}
export function TelegramAuthorize({authSuccess}: IProps) {
  const t = useTheme()
  const {openModal} = useModalControls()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<any>()
  const [busy, setBusy] = useState<boolean>(true)
  const {saveContacts, saveChannels, saveChannelParticipants} =
    useTelegramLink()

  const saveTeleInfo = useCallback(() => {
    try {
      saveChannels()
      saveChannelParticipants()
    } catch (err) {
      console.log(err)
    }
  }, [saveChannels, saveChannelParticipants])

  const saveTelegramInfon = useCallback(async () => {
    try {
      setError(null)
      const contacts = await saveContacts()
      setBusy(false)
      authSuccess(contacts)
    } catch (err: any) {
      if (err?.code === 401) {
        openModal({name: 'telegram', currentStep: TelegramBindStep.Init})
      }
      console.log('save-info-error')
      setError(err)
      setBusy(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveContacts])

  useEffect(() => {
    saveTelegramInfon()
    saveTeleInfo()
  }, [saveTeleInfo, saveTelegramInfon])

  useEffect(() => {
    setTimeout(() => setLoading(false), 300)
  }, [])

  return (
    <View style={[styles.page, a.flex_1, a.py_5xl, a.gap_2xl]}>
      <Text style={[styles.title, a.mt_2xl, t.atoms.text]}>
        <Trans>Authorization</Trans>
      </Text>
      <View style={[a.flex_row, a.align_center, a.justify_center, {gap: 24}]}>
        <View
          style={[
            {
              alignItems: 'center',
              alignSelf: 'center',
              justifyContent: 'center',
              backgroundColor: '#2AABEE',
              width: 48,
              height: 48,
            },
            a.p_lg,
            a.rounded_full,
          ]}>
          <Text style={{color: '#fff'}}>
            <Telegram
              size="lg"
              style={{
                transform: 'scale(1.1)',
                color: '#fff',
                marginRight: 3,
                marginTop: 4,
              }}
            />
          </Text>
        </View>

        <Text style={[{fontSize: 36}]}>
          <DotsHorizontal
            size="lg"
            style={{transform: 'scale(2)', color: '#D9D9D9'}}
          />
        </Text>
        <View
          style={[
            a.align_center,
            a.self_center,
            a.justify_center,
            t.atoms.bg_primary,
            {
              width: 48,
              height: 48,
            },
            a.p_lg,
            a.rounded_full,
          ]}>
          <Text style={{fontSize: 36}}>
            <LogoYellow style={{transform: 'scale(0.8)'}} />
          </Text>
        </View>
      </View>
      <Text style={[a.text_center, a.mb_4xl, t.atoms.text]}>
        <Trans>SipZ.io requests access via TelegramBot</Trans>
      </Text>
      <View style={[{gap: 12}]}>
        <Text style={[a.text_sm, a.font_semibold, t.atoms.text_sub]}>
          <Trans>The website will obtain the following：</Trans>
        </Text>
        <View style={[a.flex_row, {gap: 6}]}>
          <SaveStatus loading={loading} />
          <Text style={[t.atoms.text_sub]}>
            <Trans>User name</Trans>
          </Text>
        </View>
        <View style={[a.flex_row, {gap: 6}]}>
          <SaveStatus loading={loading} />
          <Text style={[t.atoms.text_sub]}>
            <Trans>Avatar</Trans>
          </Text>
        </View>
        <View style={[a.flex_row, {gap: 12}]}>
          <SaveStatus loading={busy} />
          <Text style={[t.atoms.text_sub]}>
            <Trans>Contacts</Trans>
          </Text>
        </View>
        {error && (
          <Button
            style={[a.flex_1, a.p_0, a.mt_2xl]}
            color="primary"
            variant="solid"
            size="large"
            label="Next"
            onPress={() => {
              saveTelegramInfon()
              saveTeleInfo()
            }}>
            <Text style={[a.font_bold, a.text_md]}>
              <Trans>Try again</Trans>
            </Text>
            {loading && <ButtonIcon size="md" icon={Loader} />}
          </Button>
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  page: {
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 19,
    textAlign: 'center',
    fontWeight: 'bold',
    marginBottom: 16,
  },
})

function SaveStatus({loading}: {loading: boolean}) {
  return loading ? (
    <Loader />
  ) : (
    <View
      style={[
        {
          alignItems: 'center',
          alignSelf: 'center',
          justifyContent: 'center',
          backgroundColor: '#0ABF52',
          width: 20,
          height: 20,
        },
        a.rounded_full,
      ]}>
      <Check size="sm" fill={'#ffffff'} style={{transform: 'scale(0.7)'}} />
    </View>
  )
}
