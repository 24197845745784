import React from 'react'
import {Pressable, Text, View} from 'react-native'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {Trans} from '@lingui/macro'
import {useQueryClient} from '@tanstack/react-query'
import dayjs from 'dayjs'

import {useModalControls} from '#/state/modals'
import {TelegramBindStep} from '#/view/com/modals/TelegramModal/config'
import {SimpleTag, Tag} from '#/view/com/tags/TagItem'
import {UserAvatar} from '#/view/com/util/UserAvatar'
import {CloseIcon} from '#/view/icons/ModalIcons'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '#/components/Button'
import * as Toggle from '#/components/forms/Toggle'
import {IconCircle} from '#/components/IconCircle'
import {ChevronRight_Stroke2_Corner0_Rounded as Chevron} from '#/components/icons/Chevron'
import {DegreeIcon} from '#/components/icons/Degree'
import {Telegram} from '#/components/icons/Telegram'
import {ContactItemProps} from '../Circle'
import {telegramContactList} from '../Circle/hooks'

type Props = {
  selected?: boolean
  showCheckbox?: boolean
  showTag?: boolean
  user: ContactItemProps
  disabled?: boolean
  maxTags?: number
  itemType?: 'menu' | 'normal'
  filterTags?: Tag[]
  showDegree?: boolean
  showInvite?: boolean
  onPress?: (item: ContactItemProps) => void
  onSelected?: (selected: boolean, item: ContactItemProps) => void
}

const degrees = [
  {
    name: <Text style={{color: 'hsla(43, 100%, 38%, 1)'}}>1st</Text>,
    icon: (
      <DegreeIcon
        style={{width: 14, height: 14, color: 'hsla(43, 100%, 38%, 1)'}}
      />
    ),
  },
  {
    name: <Text style={{color: 'hsla(86, 100%, 35%, 1)'}}>2nd</Text>,
    icon: (
      <DegreeIcon
        style={{width: 14, height: 14, color: 'hsla(86, 100%, 35%, 1)'}}
      />
    ),
  },
  {
    name: <Text style={{color: 'hsla(205, 100%, 45%, 1)'}}>3rd</Text>,
    icon: (
      <DegreeIcon
        style={{width: 14, height: 14, color: 'hsla(205, 100%, 45%, 1)'}}
      />
    ),
  },
]

export default function ContactItem({
  selected,
  showCheckbox,
  user,
  itemType,
  disabled,
  showTag = true,
  maxTags = 4,
  showDegree = true,
  showInvite = false,
  filterTags,
  onSelected,
  onPress,
}: Props) {
  const t = useTheme()
  const {openModal} = useModalControls()
  const queryClient = useQueryClient()
  const [values, setSelected] = React.useState<string[]>(() => {
    return selected ? [`${user.id}`] : []
  })

  const tags = React.useMemo(() => {
    if (!filterTags || filterTags.length === 0) {
      return user.tags
    }
    const includes = user.tags
      ?.filter(tag => {
        return filterTags?.find(_tag => _tag.id === tag.id)
      })
      .map(_tag => {
        _tag.selected = true
        return _tag
      })
    const exclusives = user.tags?.filter(tag => {
      return !includes?.find(_tag => _tag.id === tag.id)
    })
    return [...(includes || []), ...(exclusives || [])]
  }, [filterTags, user.tags])

  React.useEffect(() => {
    setSelected(selected ? [`${user.id}`] : [])
  }, [selected, user.id])

  const handleSelected = (_user: ContactItemProps) => {
    if (disabled) return
    let list = []
    if (selected) {
      list = values.filter(item => item !== _user.id)
    } else {
      list = [...values, _user.id]
    }
    setSelected(list)
    onSelected && onSelected(!selected, _user)
  }
  const degree = user.degree
  const showResent =
    user?.isInvite &&
    user?.lastInviteDate &&
    new Date().getTime() -
      dayjs(user?.lastInviteDate).add(3, 'day').unix() * 1000 >
      3 * 24 * 60 * 60 * 1000

  return (
    <Button
      style={[
        a.flex_1,
        a.flex_row,
        a.align_center,
        a.justify_between,
        // a.pr_2xl,
      ]}
      label="follower"
      onPress={() => (showCheckbox ? handleSelected(user) : onPress?.(user))}>
      <View
        style={[
          a.flex_row,
          a.align_center,
          a.p_md,
          a.gap_md,
          {paddingHorizontal: 4},
        ]}>
        <UserAvatar
          size={44}
          avatar={user.avatar}
          name={user.nickname || user.username}
          style={showInvite ? {opacity: 0.4} : {}}
        />
        <View style={[a.flex_col, a.gap_xs]}>
          <View style={[a.gap_sm]}>
            <View style={[a.flex_row, a.align_center, a.gap_xs]}>
              <Text style={[a.font_bold, t.atoms.text]}>{user.nickname}</Text>
              <Text style={[a.text_xs, t.atoms.text_sub]}>
                {user.username ? `@${user.username}` : ''}
              </Text>
              {user.isMutual && (
                <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                  <IconCircle
                    style={[
                      {width: 16, height: 16, backgroundColor: '#2aabee'},
                    ]}
                    icon={Telegram}
                    iconStyle={{color: t.palette.white}}
                    size="xxs"
                  />
                  <Text style={[a.text_xs, t.atoms.text]}>
                    <Trans>Mutual</Trans>
                  </Text>
                </View>
              )}
              {showDegree && degree && degrees[degree - 1] && (
                <View style={[a.flex_row, a.align_center, a.gap_xs]}>
                  {degrees[degree - 1].icon}
                  {degrees[degree - 1].name}
                </View>
              )}
            </View>
          </View>
          {showTag && user.tags && (
            <View style={[a.flex_row, a.align_center, a.flex_wrap, a.gap_sm]}>
              {tags?.slice(0, maxTags).map(tag => (
                <SimpleTag
                  key={tag.name}
                  tag={tag}
                  isFilter={tag.selected}
                  style={{height: 16}}
                />
              ))}
            </View>
          )}
        </View>
      </View>
      <View
        style={[a.flex_1, a.flex_row, a.align_center, a.justify_end, a.gap_md]}>
        {showCheckbox && (
          <Toggle.Group
            disabled={disabled}
            type="checkbox"
            label="Mask"
            values={values}
            onChange={() => handleSelected(user)}>
            <Toggle.Item name={`${user.id}`} label="Switch Mask">
              <Toggle.Checkbox />
            </Toggle.Item>
          </Toggle.Group>
        )}
        {itemType && <Chevron fill={t.atoms.text_sub.color} />}
        {showInvite && (
          <>
            {user?.isInvite && (
              <View style={[a.flex_row, a.gap_xs]}>
                <FontAwesomeIcon icon={'check'} color={t.palette.gray_10} />
                <Text
                  style={[
                    a.text_xs,
                    a.font_semibold,
                    {color: t.palette.gray_10},
                  ]}>
                  Invited
                </Text>
              </View>
            )}

            {(!user?.isInvite || showResent) && (
              <Pressable
                accessibilityRole="button"
                onPress={() => {
                  openModal({
                    name: 'telegram',
                    currentStep: TelegramBindStep.SendMessage,
                    userIds: [user.id],
                    onCallBack: () => {
                      queryClient.invalidateQueries({
                        queryKey: [telegramContactList],
                      })
                    },
                  })
                }}
                style={[
                  t.atoms.bg_primary,
                  a.justify_center,
                  a.align_center,
                  {width: 64, height: 24, borderRadius: 100},
                ]}>
                <View>
                  <Text style={[a.text_xs, a.font_semibold]}>
                    {!showResent ? 'Invite' : 'Resent'}
                  </Text>
                </View>
              </Pressable>
            )}
          </>
        )}
      </View>
    </Button>
  )
}

export const SmallContact = ({user, disabled, onSelected}: Props) => {
  const t = useTheme()
  return (
    <Button
      style={[
        a.flex_row,
        a.align_center,
        a.justify_between,
        {
          backgroundColor: 'hsla(49, 100%, 89%, 1)',
          height: 32,
          borderWidth: 1,
          borderColor: 'hsla(43, 100%, 38%, 1)',
        },
        a.rounded_full,
        // a.pr_2xl,
      ]}
      label="follower"
      onPress={() => {
        if (disabled) return
        onSelected?.(false, user)
      }}>
      <View style={[a.flex_row, a.align_center, a.p_xs, a.gap_xs]}>
        <UserAvatar
          type="user"
          size={24}
          avatar={user.avatar}
          name={user.nickname || user.username}
        />
        <View style={[a.flex_col]}>
          <View style={[a.flex_row, a.align_center]}>
            <Text style={[{fontSize: 12, color: t.palette.black}, a.font_bold]}>
              {user.nickname}
            </Text>
          </View>
          <View style={[a.flex_row, a.align_center]}>
            <Text
              style={[
                {fontSize: 10},
                a.text_xs,
                t.atoms.text_contrast_low,
                {color: 'hsla(0, 0%, 40%, 1)'},
              ]}>
              {user.username ? `@${user.username}` : ''}
            </Text>
          </View>
        </View>
        {!disabled && <CloseIcon circleColor={t.palette.black} />}
      </View>
    </Button>
  )
}
