import React from 'react'
import {Text, View} from 'react-native'

import {CircleItemProps} from '#/view/screens/Circle'
import {CircleAvatar} from '#/view/screens/Circle/CircleAvatar'
import {atoms as a, useTheme} from '#/alf'

export const StackCircleAvatar = ({
  data,
  maxCount = 5,
  size = 24,
}: {
  data: CircleItemProps[]
  maxCount?: number
  size?: number
}) => {
  const t = useTheme()
  const avatars: CircleItemProps[] = []
  const absoluteWidth = (size / 3) * 2
  let width = 15
  data?.forEach(item => {
    if (item && avatars.length < maxCount) {
      width += absoluteWidth
      avatars.push(item)
    }
  })

  return (
    <View
      style={[
        a.flex_row,
        a.relative,
        {width: width + (data?.length > maxCount ? absoluteWidth : 0)},
      ]}>
      {avatars?.map((item, index: number) => {
        return item ? (
          <View
            style={[
              a.align_center,
              a.justify_center,
              {
                width: size + 4,
                height: size + 4,
                borderRadius: 4,
                borderColor: t.atoms.modalBg2.backgroundColor,
                borderWidth: 2,
              },
              index > 0
                ? {position: 'absolute', left: absoluteWidth * index}
                : {},
            ]}
            key={index}>
            <CircleAvatar size={size} circle={item} />
          </View>
        ) : null
      })}
      {data?.length > maxCount && (
        <View
          style={[
            a.align_center,
            a.justify_center,
            {
              backgroundColor: t.palette.primary,
              width: size + 4,
              height: size + 4,
              borderRadius: 6,
              borderColor: '#fff',
              borderWidth: 2,
              position: 'absolute',
              left: absoluteWidth * maxCount,
            },
          ]}>
          <Text style={{color: t.palette.black}}>
            +{data?.length - maxCount}
          </Text>
        </View>
      )}
    </View>
  )
}
