import React from 'react'
import {StyleSheet, Text, TouchableOpacity, View} from 'react-native'
import {ComAtprotoServerGetCircleUsers} from '@atproto/api'
import {Trans} from '@lingui/macro'

import {UserAvatar} from '#/view/com/util/UserAvatar'
import {atoms as a, useTheme} from '#/alf'
import {Ellipsis} from '#/components/icons/Ellipsis'

export function CircleMemberItem({
  item,
  onDelete,
  isOwner,
}: {
  item: ComAtprotoServerGetCircleUsers.User
  onDelete?: (id: string) => void
  isOwner: boolean
}) {
  const t = useTheme()
  return (
    <View style={[styles.page]}>
      <View style={[a.flex_row, a.align_center]}>
        <UserAvatar
          size={40}
          avatar={(item.avatar as {url: string})?.url}
          type="user"
          name={item.user_name}
        />
        <View style={styles.userInfo}>
          <Text style={[styles.userName, t.atoms.text]}>
            {item.user_name}
            {isOwner && (
              <Text style={[styles.tag]}>
                <Trans>Owner</Trans>
              </Text>
            )}
          </Text>
          <Text style={[styles.userHandle, t.atoms.text_sub]}>
            @{item.user_name}
          </Text>
        </View>
      </View>
      <TouchableOpacity
        accessibilityRole="button"
        onPress={() => onDelete?.(item.source_user_id)}>
        <Ellipsis style={[t.atoms.text]} />
      </TouchableOpacity>
    </View>
  )
}
const styles = StyleSheet.create({
  page: {
    width: '100%',
    paddingVertical: 10,
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  userInfo: {
    marginLeft: 10,
    paddingVertical: 3,
    justifyContent: 'space-between',
  },
  userName: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  userHandle: {
    fontSize: 12,
  },
  tag: {
    marginLeft: 5,
    backgroundColor: '#57bc5f',
    padding: 4,
    borderRadius: 4,
    color: '#fff',
    fontSize: 12,
  },
})
