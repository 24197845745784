import React, {useEffect} from 'react'
import {Pressable, SafeAreaView, Text, View} from 'react-native'
import {msg, Trans} from '@lingui/macro'
import {useLingui} from '@lingui/react'

import {useWebMediaQueries} from '#/lib/hooks/useWebMediaQueries'
import {useModalControls} from '#/state/modals'
import {Tag} from '#/view/com/tags/TagItem'
import {FriendsIcon, RightIcon, TagIcon} from '#/view/icons/ModalIcons'
import {CircleItemProps, ContactItemProps} from '#/view/screens/Circle'
import {atoms as a, useTheme} from '#/alf'
import {Button} from '../../util/forms/Button'
import {TVisible} from '../Visible'
import {SelectCircleList} from './SelectCircleList'

export interface CircleListType extends CircleItemProps {
  isSelected?: boolean
}

export type VisibleRangeType = {
  visibleCircles: CircleItemProps[]
  unVisibleTags: Tag[]
  unVisibleFriends: ContactItemProps[]
}
export type PostSelectCircleModalProps = {
  visibleRange: VisibleRangeType
  setVisibleRange: (value: VisibleRangeType) => void
  setVisible: (value: TVisible) => void
}
export const snapPoints = ['fullscreen']
export const Component = function PostSelectCircleModalComponent(
  props: PostSelectCircleModalProps,
) {
  const t = useTheme()
  const {_} = useLingui()
  const {isMobile, isDesktop} = useWebMediaQueries()
  const {closeModal, closeAllModals, openModal} = useModalControls()

  const [isVisibleExpand, setIsVisibleExpand] = React.useState<boolean>(true)
  const [isUnvisibleExpand, setIsUnvisibleExpand] =
    React.useState<boolean>(false)
  const [list, setList] = React.useState<CircleListType[]>([])
  const [friends, setFriends] = React.useState<ContactItemProps[]>([])
  const [tags, setTags] = React.useState<Tag[]>([])

  function onConfirm() {
    props?.setVisible('Circle')
    props?.setVisibleRange({
      visibleCircles: list.filter(item => item.isSelected),
      unVisibleTags: tags,
      unVisibleFriends: friends,
    })
    closeAllModals()
  }

  useEffect(() => {
    setFriends(props.visibleRange.unVisibleFriends)
  }, [props.visibleRange.unVisibleFriends])

  useEffect(() => {
    setTags(props.visibleRange.unVisibleTags)
  }, [props.visibleRange.unVisibleTags])

  // console.log('xxxx-friends', friends);

  return (
    <SafeAreaView
      testID="postSelectCircleModal"
      style={[a.flex_1, isMobile && {paddingHorizontal: 18}]}>
      <View style={[a.flex_1]}>
        <Pressable
          accessibilityRole="button"
          onPress={() => setIsVisibleExpand(!isVisibleExpand)}
          style={[
            a.flex_row,
            a.align_center,
            a.justify_between,
            !isVisibleExpand ? a.pb_md : a.mb_md,
          ]}>
          <View style={[a.flex_row, a.align_center, a.gap_xs]}>
            <Text style={[a.text_md, a.font_bold, t.atoms.text]}>
              Visible To
            </Text>
          </View>
          <View style={[a.flex_row, a.align_center, a.gap_xs]}>
            <Text style={[a.text_sm, {color: t.palette.gray_10}]}>
              {list.filter((item: CircleListType) => item.isSelected)?.length}{' '}
              selected
            </Text>
            <RightIcon
              style={[
                !isVisibleExpand
                  ? {transform: 'rotate(90deg)', marginTop: 6}
                  : {transform: 'rotate(270deg)', marginBottom: 3},
              ]}
              color={t.palette.gray_10}
            />
          </View>
        </Pressable>
        {isVisibleExpand && (
          <SelectCircleList
            visibleRange={props?.visibleRange}
            list={list}
            setList={setList}
          />
        )}

        <Pressable
          accessibilityRole="button"
          onPress={() => setIsUnvisibleExpand(!isUnvisibleExpand)}
          style={[
            a.flex_row,
            a.align_center,
            a.justify_between,
            a.pt_md,
            {borderTopWidth: 1, borderColor: '#97979740'},
          ]}>
          <View style={[a.flex_row, a.align_center, a.gap_xs]}>
            <Text style={[a.text_md, a.font_bold, t.atoms.text]}>
              <Trans>except</Trans>
            </Text>
          </View>
          <View style={[a.flex_row, a.align_center, a.gap_xs]}>
            <RightIcon
              style={[
                !isUnvisibleExpand
                  ? {transform: 'rotate(90deg)', marginTop: 6}
                  : {transform: 'rotate(270deg)', marginBottom: 3},
              ]}
              color={t.palette.gray_10}
            />
          </View>
        </Pressable>
        {isUnvisibleExpand && (
          <View style={[a.gap_md]}>
            <Pressable
              accessibilityRole="button"
              onPress={() => {
                openModal({
                  name: 'post-select-unvisible',
                  type: 'tag',
                  tags,
                  setTags,
                })
              }}
              style={[
                a.gap_xs,
                a.py_md,
                {borderBottomWidth: 1, borderColor: '#97979740'},
              ]}>
              <View style={[a.flex_row, a.align_center, a.gap_sm]}>
                <TagIcon />
                <Text style={[a.text_sm, a.font_semibold, t.atoms.text_sub]}>
                  Select Tags
                </Text>
              </View>
              <Text style={[a.text_sm, {color: '#FF543D'}]}>
                {tags?.map(item => item.name).join(', ')}
              </Text>
            </Pressable>

            <Pressable
              accessibilityRole="button"
              onPress={() => {
                openModal({
                  name: 'post-select-unvisible',
                  type: 'friend',
                  friends,
                  setFriends,
                })
              }}
              style={[a.gap_xs, a.py_md]}>
              <View style={[a.flex_row, a.align_center, a.gap_sm]}>
                <FriendsIcon />
                <Text style={[a.text_sm, a.font_semibold, t.atoms.text_sub]}>
                  Select Friends
                </Text>
              </View>
              <Text style={[a.text_sm, {color: '#FF543D'}]}>
                {friends?.map(item => `@${item.nickname} `)}
              </Text>
            </Pressable>
          </View>
        )}
      </View>
      <View style={[a.mt_5xl, a.gap_md, isDesktop ? a.flex_row_reverse : {}]}>
        <Button
          testID="confirmBtn"
          type="primary"
          accessibilityLabel={_(msg`Confirm`)}
          accessibilityHint=""
          label={_(msg`Confirm`)}
          onPress={onConfirm}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md, {color: t.palette.black}]}
          style={[
            isDesktop ? a.flex_1 : {},
            {backgroundColor: t.palette.primary},
          ]}
        />
        <Button
          testID="cancelBtn"
          type="default"
          onPress={() => {
            closeModal()
          }}
          accessibilityLabel={_(msg`Back`)}
          accessibilityHint=""
          label={_(msg`Back`)}
          labelContainerStyle={{justifyContent: 'center', padding: 4}}
          labelStyle={[a.text_md]}
          style={[isDesktop ? a.flex_1 : {}]}
        />
      </View>
    </SafeAreaView>
  )
}
