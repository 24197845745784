import React from 'react'
import {
  Image,
  ImageBackground,
  Pressable,
  StyleSheet,
  Text,
  View,
} from 'react-native'
import {LinearGradient} from 'expo-linear-gradient'
import {AppBskyFeedDefs, AtUri, ComAtprotoServerDefs} from '@atproto/api'
import {FontAwesomeIcon} from '@fortawesome/react-native-fontawesome'
import {msg} from '@lingui/macro'
import {useLingui} from '@lingui/react'
import {useNavigation} from '@react-navigation/native'
import dayjs from 'dayjs'
import {ethers} from 'ethers'

import {Shadow} from '#/state/cache/post-shadow'
import {useModalControls} from '#/state/modals'
import {NavigationProp} from 'lib/routes/types'
import {atoms as a, useTheme} from '#/alf'
import {Button, ButtonText} from '#/components/Button'
import {BetIcon, CircleChevronRightIcon} from '#/components/icons/StakeIcons'
import {BetCountDown} from './BetCountDown'
import {BetProgress} from './BetProgress'

const postBetChallengeBg = require('../../../../assets/imgs/post_bet_challenge_detail.png')
const finishedIcon = require('../../../../assets/imgs/bet_finshed.png')
const betOnWin = require('../../../../assets/imgs/bet_on_win.png')
const betAgainstWin = require('../../../../assets/imgs/bet_against_win.png')
const betEqual = require('../../../../assets/imgs/bet_no_winner.png')
const betInvalid = require('../../../../assets/imgs/bet_invalid.png')

type EndResultWinType = 'on' | 'against' | 'equal' | 'invalid'
export type BetDetail = {
  isEnd: boolean
  endResult?:
    | {
        win: EndResultWinType
        status: 'won' | 'lost' | 'equal' | 'invalid'
        value: string
        onSocore: number | undefined
        againstScore: number | undefined
        serviceFee: any
      }
    | undefined
}
// Bet result: 1 betOn win，2 betOn lose，3 draw, -1 invalid
const BET_RESULT_CONFIG: {[key: string]: string} = {
  '1': 'on',
  '2': 'against',
  '3': 'equal',
  '-1': 'invalid',
}

export const PostBetDetail = ({
  post,
  betSummary,
}: {
  post: Shadow<AppBskyFeedDefs.PostView>
  betSummary?: ComAtprotoServerDefs.BetTransSummary
}) => {
  const navigation = useNavigation<NavigationProp>()
  const t = useTheme()
  const {_} = useLingui()
  const {openModal} = useModalControls()
  const betSummaryResult = betSummary?.result
  const betDetail: BetDetail = {
    isEnd: betSummaryResult ? true : false,
    endResult: betSummaryResult
      ? {
          win: (betSummaryResult?.result
            ? BET_RESULT_CONFIG?.[betSummaryResult?.result + '']
            : 'invalid') as EndResultWinType,
          status:
            betSummaryResult?.result === 3
              ? 'equal'
              : betSummaryResult?.result === -1
              ? 'invalid'
              : betSummaryResult?.balanceAmount > 0
              ? 'won'
              : 'lost',
          value: ethers.formatUnits(betSummaryResult?.balanceAmount, 6),
          onSocore: betSummaryResult?.betOnScore,
          againstScore: betSummaryResult?.betAgainstScore,
          serviceFee: betSummaryResult?.fee,
        }
      : undefined,
  }

  return (
    <View style={[a.border_t, {borderColor: t.palette.contrast_100}]}>
      <ImageBackground
        source={postBetChallengeBg}
        resizeMode="cover"
        style={[styles.postBetBg, a.relative]}>
        <View>
          <LinearGradient
            colors={['rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 0)']}
            style={[a.absolute, a.inset_0, {height: '100%'}]}
          />
          {betDetail?.isEnd && betDetail?.endResult?.win === 'on' && (
            <Image
              source={betOnWin}
              style={[styles.winIcon]}
              accessibilityIgnoresInvertColors
            />
          )}
          {betDetail?.isEnd && betDetail?.endResult?.win === 'against' && (
            <Image
              source={betAgainstWin}
              style={[styles.winIcon]}
              accessibilityIgnoresInvertColors
            />
          )}
          {betDetail?.isEnd && betDetail?.endResult?.win === 'equal' && (
            <Image
              source={betEqual}
              style={[styles.winIcon]}
              accessibilityIgnoresInvertColors
            />
          )}
          {betDetail?.isEnd && betDetail?.endResult?.win === 'invalid' && (
            <Image
              source={betInvalid}
              style={[styles.winIcon]}
              accessibilityIgnoresInvertColors
            />
          )}
          {betDetail?.isEnd && (
            <Image
              source={finishedIcon}
              style={[styles.finishedIcon]}
              accessibilityIgnoresInvertColors
            />
          )}
          <View style={[a.p_lg]}>
            <View style={[a.flex_row, a.justify_between, a.align_center]}>
              <Text
                style={[a.text_xs, a.font_bold, {color: t.palette.black_25}]}>
                Prize Pool:
              </Text>
              <View style={[a.flex_row, a.align_baseline]}>
                <Text style={[a.text_3xl, a.font_heavy]}>
                  {betSummary?.totalAmount ?? 0}
                </Text>
                <Text style={[a.text_xs, a.font_heavy, a.ml_xs]}>USDC</Text>
              </View>
            </View>
            <View style={[a.my_md, a.mx_sm]}>
              <BetProgress
                onValue={betSummary?.totalBetOnAmount ?? 0}
                againstValue={betSummary?.totalBetAgainstAmount ?? 0}
                post={post}
                isMask={
                  betDetail?.isEnd ? false : !(betSummary?.public_side ?? false)
                }
              />
            </View>
            {betDetail.isEnd && (
              <View style={[a.flex_1, a.align_end, a.justify_end]}>
                <Text style={[a.text_lg, a.font_bold]}>
                  {betDetail.endResult?.status === 'won' && 'You won'}
                  {betDetail.endResult?.status === 'lost' && 'You lost'}
                  {betDetail.endResult?.status === 'equal' &&
                    `Refund ${Number(betDetail.endResult?.value)} USDC`}
                  {betDetail.endResult?.status === 'invalid' && 'Refund'}
                </Text>
                <View style={[a.flex_row, a.align_center, a.my_xs]}>
                  <Text style={[a.text_lg, a.font_heavy, a.mr_xs]}>
                    {betDetail.endResult?.status === 'equal' &&
                      `Service fee ${betDetail.endResult?.serviceFee} USDC`}
                    {betDetail.endResult?.status === 'invalid' &&
                      `${Number(betDetail.endResult?.value)} USDC`}
                    {betDetail.endResult?.status &&
                      ['won', 'lost'].indexOf(betDetail.endResult?.status) >=
                        0 &&
                      `${betDetail.endResult?.value} USDC`}
                  </Text>
                  <Pressable
                    accessibilityRole="button"
                    onPress={() => {
                      navigation.navigate('TransAssets')
                    }}>
                    <CircleChevronRightIcon size={14} />
                  </Pressable>
                </View>
              </View>
            )}
            <View style={[a.flex_row, a.justify_between, a.align_center]}>
              <Text
                style={[
                  a.text_xs,
                  a.font_semibold,
                  {color: t.palette.black_25},
                ]}>
                Result determination:
              </Text>
              {!betDetail.isEnd ? (
                <Text style={[a.text_sm, a.font_bold]}>Popularity</Text>
              ) : (
                <Pressable
                  accessibilityRole="button"
                  onPress={() => {
                    navigation.navigate('BetScoreList', {
                      did: post.author.did,
                      rkey: new AtUri(post.uri).rkey,
                      dataType: 'bet-on',
                    })
                  }}>
                  <View
                    style={[
                      a.px_xs,
                      a.flex_row,
                      a.align_center,
                      {
                        backgroundColor: t.palette.green_11,
                        borderRadius: 6,
                        paddingRight: 6,
                        paddingVertical: 2,
                      },
                    ]}>
                    <Text
                      style={[
                        a.text_sm,
                        a.font_semibold,
                        {color: t.palette.white},
                      ]}>
                      Popularity {betDetail.endResult?.onSocore} :{' '}
                      {betDetail.endResult?.againstScore}
                    </Text>
                    <FontAwesomeIcon
                      icon="angle-right"
                      style={{color: t.palette.white}}
                      size={12}
                    />
                  </View>
                </Pressable>
              )}
            </View>
            {!betDetail?.isEnd && (
              <View
                style={[
                  a.flex_row,
                  a.justify_between,
                  a.align_center,
                  a.my_xs,
                ]}>
                <Text
                  style={[
                    a.text_xs,
                    a.font_semibold,
                    {color: t.palette.black_25},
                  ]}>
                  Betting deadline:
                </Text>
                <BetCountDown
                  endTime={dayjs(betSummary?.bet_deadline).unix() * 1000}
                />
              </View>
            )}
            <View
              style={[a.flex_row, a.align_center, a.justify_between, a.mt_md]}>
              <View style={[a.flex_row, a.align_center]}>
                <Text
                  style={[
                    a.text_xs,
                    a.font_semibold,
                    a.mr_xs,
                    {color: t.palette.black_50},
                  ]}>
                  Learn about the rules
                </Text>
                <CircleChevronRightIcon />
              </View>
              {!betDetail.isEnd && (
                <Button
                  testID="confirmBtn"
                  accessibilityLabel={_(msg`Bet`)}
                  accessibilityHint=""
                  label={_(msg`Bet`)}
                  onPress={() => {
                    openModal({
                      name: 'earn-bet',
                      type: 'join-bet',
                      rKey: new AtUri(post.uri).rkey,
                      transPostAuthor: {
                        did: post.author.did,
                        avatar: post.author.avatar ?? '',
                        displayName: post.author.displayName ?? '',
                      },
                      amount: 5,
                      publicSide: betSummary?.public_side,
                    })
                  }}
                  style={[
                    {
                      borderRadius: 30,
                      paddingVertical: 4,
                      paddingHorizontal: 24,
                      backgroundColor: t.palette.primary,
                      borderWidth: 2,
                      borderColor: t.palette.white,
                    },
                  ]}>
                  <BetIcon primaryColor="#000" />
                  <ButtonText
                    style={[
                      a.text_md,
                      a.font_bold,
                      a.ml_xs,
                      {color: t.palette.black},
                    ]}>
                    {_(msg`Bet`)}
                  </ButtonText>
                </Button>
              )}
            </View>
          </View>
        </View>
      </ImageBackground>
    </View>
  )
}

const styles = StyleSheet.create({
  postBetBg: {
    flex: 1,
    justifyContent: 'center',
  },
  finishedIcon: {
    zIndex: 3,
    width: 64,
    height: 64,
    position: 'absolute',
    top: 3,
    left: '50%',
  },
  winIcon: {
    zIndex: 3,
    width: 130,
    height: 130,
    position: 'absolute',
    top: 5,
    left: '50%',
    transform: [{translateX: -65}],
  },
})
